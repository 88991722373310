import {
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  AUTH_LOGIN,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGOUT_SUCCESS,
  AUTH_SYNC_USER_METADATA,
  AUTH_SYNC_USER_METADATA_SUCCESS,
  AUTH_SYNC_USER_METADATA_FAILURE,
  AUTH_FETCH_USER_PROFILE,
  AUTH_FETCH_USER_PROFILE_SUCCESS,
  AUTH_FETCH_USER_PROFILE_FAILURE,
  AUTH_UPDATE_USER_PROFILE,
  AUTH_UPDATE_USER_PROFILE_SUCCESS,
  AUTH_UPDATE_USER_PROFILE_FAILURE,
  AUTH_FETCH_USER_SETTINGS,
  AUTH_FETCH_USER_SETTINGS_SUCCESS,
  AUTH_FETCH_USER_SETTINGS_FAILURE,
  AUTH_UPDATE_USER_SETTINGS,
  AUTH_UPDATE_USER_SETTINGS_SUCCESS,
  AUTH_UPDATE_USER_SETTINGS_FAILURE,
  AUTH_REAUTH_USER,
  AUTH_REAUTH_USER_SUCCESS,
  AUTH_REAUTH_USER_FAILURE,
  AUTH_REAUTH_USER_REQUIRE,
  AUTH_CHANGE_PASSWORD,
  AUTH_CHANGE_PASSWORD_SUCCESS,
  AUTH_CHANGE_PASSWORD_FAILURE,
  AUTH_REQUEST_PASSWORD_RESET,
  AUTH_REQUEST_PASSWORD_RESET_SUCCESS,
  AUTH_REQUEST_PASSWORD_RESET_FAILURE,
  AUTH_CLEAR_ERROR,
  AUTH_SIGNUP,
  AUTH_SIGNUP_SUCCESS,
  AUTH_SIGNUP_FAILURE,
  AUTH0_LOGIN,
  AUTH0_LOGIN_SUCCESS,
  AUTH_LOGOUT,
} from "redux/constants/ActionTypes";

const INIT_STATE = {
  initURL: "",
  loaderType: "",
  loading: false,
  loaded: false,
  alertType: "",
  alertMessage: "",
  showAlert: false,
  user: null,
  userProfile: null,
  userSettings: null,
  userMetadata: null,
  signup: false,
  reAuthDone: false,
  reAuthLoading: false,
  error: ""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      };
    }

    case ON_SHOW_LOADER: {
      return {
        ...state,
        loading: true
      };
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loading: false
      };
    }

    case AUTH_SIGNUP: {
      return {
        ...state,
        loaded: false,
        loading: true,
        signup: true,
        error: null,
        loaderType: "signup"
      };
    }

    case AUTH_SIGNUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        signup: false,
        error: null,
        loaderType: "",
      };
    }

    case AUTH_SIGNUP_FAILURE: {
      return {
        ...state,
        loading: false,
        signup: false,
        error: action.payload,
        loaderType: ""
      };
    }

    case AUTH_LOGOUT: {
      return {}
    }

    case AUTH_LOGOUT_SUCCESS: {
      return {
        ...state,
        loaded: false,
        loading: false,
        user: null,
        userInfo: null,
        userProfile: INIT_STATE.userProfile,
        userSettings: INIT_STATE.userSettings,
        userMetadata: INIT_STATE.userMetadata
      };
    }

    case AUTH_LOGIN: {
      return {
        ...state,
        loading: true,
        error: ""
      };
    }

    case AUTH_LOGIN_SUCCESS: {
      const isAuth0User = action.payload.signInProvider === "custom";
      let userInfo = state.userInfo;
      let user = state.user;
      if (!isAuth0User) {
        user = {
          displayName: action.payload.displayName,
          email: action.payload.email,
          emailVerified: action.payload.emailVerified,
          phoneNumber: action.payload.phoneNumber,
          photoURL: action.payload.photoURL,
          uid: action.payload.uid,
          role: action.payload.role,
          cid: action.payload.cid,
          sid: action.payload.sid,
          sids: action.payload.sids,
        }
        userInfo = {
          displayName: action.payload.displayName,
          email: action.payload.email,
          emailVerified: action.payload.emailVerified,
          phoneNumber: action.payload.phoneNumber,
          photoURL: action.payload.photoURL,
          uid: action.payload.uid,
          role: action.payload.role,
          cid: action.payload.cid,
          sid: action.payload.sid,
          sids: action.payload.sids,
        }
      } else {
        user = {
          ...user,
          role: action.payload.role,
        }
      }

      return {
        ...state,
        loading: false,
        loaded: false,
        user: action.payload,
      };
    }

    case AUTH0_LOGIN: {
      return {
        ...state,
        loading: true,
        error: ""
      };
    }

    case AUTH0_LOGIN_SUCCESS: {
      const user = {
        displayName: action.payload.user.name,
        email: action.payload.user.email,
        emailVerified: action.payload.user.email_verified,
        // phoneNumber: action.payload.user.phoneNumber,
        photoURL: action.payload.user.picture,
        uid: action.payload.user.sub,
        cid: action.payload.user.sub,
        role: action.payload.user.renraccoon.role,
        sid: action.payload.user.renraccoon.sids[0],
        sids: action.payload.user.renraccoon.sids,
        locale: action.payload.user.locale
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        user,
      };
    }

    case AUTH_LOGIN_FAILURE: {

      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };
    }

    case AUTH_SYNC_USER_METADATA: {
      return {
        ...state,
        loading: true
      };
    }

    case AUTH_SYNC_USER_METADATA_SUCCESS: {
      let loading = true;
      let loaded = false;

      if (state.user && state.userProfile && state.userSettings) {
        loading = false;
        loaded = true;
      }

      return {
        ...state,
        loading: loading,
        loaded: loaded,
        userMetadata: action.payload
      };
    }

    case AUTH_SYNC_USER_METADATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case AUTH_FETCH_USER_PROFILE: {
      return {
        ...state,
        loading: true
      };
    }

    case AUTH_FETCH_USER_PROFILE_SUCCESS: {
      let loading = true;
      let loaded = false;

      if (state.user && state.userMetadata && state.userSettings) {
        loading = false;
        loaded = true;
      }

      return {
        ...state,
        loading: loading,
        loaded: loaded,
        userProfile: action.payload
      };
    }

    case AUTH_FETCH_USER_PROFILE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case AUTH_UPDATE_USER_PROFILE: {
      return {
        ...state,
        loading: true
      };
    }

    case AUTH_UPDATE_USER_PROFILE_SUCCESS: {
      let loading = true;
      let loaded = false;

      if (state.user && state.userMetadata && state.userSettings) {
        loading = false;
        loaded = true;
      }

      return {
        ...state,
        loading: loading,
        loaded: loaded,
        userProfile: action.payload
      };
    }

    case AUTH_UPDATE_USER_PROFILE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case AUTH_FETCH_USER_SETTINGS: {
      return {
        ...state,
        loading: true
      };
    }

    case AUTH_FETCH_USER_SETTINGS_SUCCESS: {
      let loading = true;
      let loaded = false;

      if (state.user && state.userMetadata && state.userProfile) {
        loading = false;
        loaded = true;
      }

      return {
        ...state,
        loading: loading,
        loaded: loaded,
        userSettings: action.payload
      };
    }

    case AUTH_FETCH_USER_SETTINGS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case AUTH_UPDATE_USER_SETTINGS: {
      return {
        ...state,
        loading: true
      };
    }

    case AUTH_UPDATE_USER_SETTINGS_SUCCESS: {
      let loading = true;
      let loaded = false;

      if (state.user && state.userMetadata && state.userProfile) {
        loading = false;
        loaded = true;
      }

      return {
        ...state,
        loading: loading,
        loaded: loaded,
        userSettings: action.payload
      };
    }

    case AUTH_UPDATE_USER_SETTINGS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case AUTH_REAUTH_USER: {
      return {
        ...state,
        reAuthDone: false,
        reAuthLoading: true,
        reAuthError: ""
      };
    }
    case AUTH_REAUTH_USER_SUCCESS: {
      return {
        ...state,
        reAuthDone: true,
        reAuthLoading: false
      };
    }
    case AUTH_REAUTH_USER_FAILURE: {
      return {
        ...state,
        reAuthDone: false,
        reAuthLoading: false,
        reAuthError: action.payload
      };
    }

    case AUTH_REAUTH_USER_REQUIRE: {
      return {
        ...state,
        reAuthDone: false,
        reAuthLoading: false,
        reAuthError: ""
      };
    }

    case AUTH_CHANGE_PASSWORD: {
      return {
        ...state,
        reAuthDone: false,
        reAuthLoading: false,
        reAuthError: ""
      };
    }

    case AUTH_CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state
      };
    }

    case AUTH_CHANGE_PASSWORD_FAILURE: {
      return {
        ...state,
        error: action.payload
      };
    }

    case AUTH_REQUEST_PASSWORD_RESET: {
      return {
        ...state,
        loading: true
      };
    }

    case AUTH_REQUEST_PASSWORD_RESET_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: ""
      };
    }

    case AUTH_REQUEST_PASSWORD_RESET_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }

    case AUTH_CLEAR_ERROR: {
      return {
        ...state,
        loading: false,
        error: ""
      };
    }

    default:
      return state;
  }
};
