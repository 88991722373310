import {
  ANNOUNCEMENT_SEND_ANNOUNCEMENT,
  ANNOUNCEMENT_SEND_ANNOUNCEMENT_SUCCESS,
  ANNOUNCEMENT_SEND_ANNOUNCEMENT_FAILURE,
  ANNOUNCEMENT_SEND_ANNOUNCEMENT_DRAFT,
  ANNOUNCEMENT_SEND_ANNOUNCEMENT_DRAFT_SUCCESS,
  ANNOUNCEMENT_SEND_ANNOUNCEMENT_DRAFT_FAILURE,
  ANNOUNCEMENT_DRAFT_ANNOUNCEMENT,
  ANNOUNCEMENT_DRAFT_ANNOUNCEMENT_SUCCESS,
  ANNOUNCEMENT_DRAFT_ANNOUNCEMENT_FAILURE,
  ANNOUNCEMENT_UPDATE_ANNOUNCEMENT,
  ANNOUNCEMENT_UPDATE_ANNOUNCEMENT_SUCCESS,
  ANNOUNCEMENT_UPDATE_ANNOUNCEMENT_FAILURE,
  ANNOUNCEMENT_UPDATE_ANNOUNCEMENT_DRAFT,
  ANNOUNCEMENT_UPDATE_ANNOUNCEMENT_DRAFT_SUCCESS,
  ANNOUNCEMENT_UPDATE_ANNOUNCEMENT_DRAFT_FAILURE,
  ANNOUNCEMENT_DELETE_ANNOUNCEMENT_SENT,
  ANNOUNCEMENT_DELETE_ANNOUNCEMENT_SENT_SUCCESS,
  ANNOUNCEMENT_DELETE_ANNOUNCEMENT_SENT_FAILURE,
  ANNOUNCEMENT_DELETE_ANNOUNCEMENT_DRAFT,
  ANNOUNCEMENT_DELETE_ANNOUNCEMENT_DRAFT_SUCCESS,
  ANNOUNCEMENT_DELETE_ANNOUNCEMENT_DRAFT_FAILURE,
  ANNOUNCEMENT_FETCH_ANNOUNCEMENT,
  ANNOUNCEMENT_FETCH_ANNOUNCEMENT_SUCCESS,
  ANNOUNCEMENT_FETCH_ANNOUNCEMENT_FAILURE,
  ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_RECENT,
  ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_RECENT_SUCCESS,
  ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_RECENT_FAILURE,
  ANNOUNCEMENT_UNSYNC_ANNOUNCEMENT_LIST_RECENT,
  ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_SENT,
  ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_SENT_SUCCESS,
  ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_SENT_FAILURE,
  ANNOUNCEMENT_UNSYNC_ANNOUNCEMENT_LIST_SENT,
  ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_DRAFT,
  ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_DRAFT_SUCCESS,
  ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_DRAFT_FAILURE,
  ANNOUNCEMENT_UNSYNC_ANNOUNCEMENT_LIST_DRAFT
} from "redux/constants/ActionTypes";

export const announcementSendAnnouncement = payload => {
  return {
    type: ANNOUNCEMENT_SEND_ANNOUNCEMENT,
    payload: payload
  };
};

export const announcementSendAnnouncementSuccess = payload => {
  return {
    type: ANNOUNCEMENT_SEND_ANNOUNCEMENT_SUCCESS,
    payload: payload
  };
};

export const announcementSendAnnouncementFailure = payload => {
  return {
    type: ANNOUNCEMENT_SEND_ANNOUNCEMENT_FAILURE,
    payload: payload
  };
};

export const announcementSendAnnouncementDraft = payload => {
  return {
    type: ANNOUNCEMENT_SEND_ANNOUNCEMENT_DRAFT,
    payload: payload
  };
};

export const announcementSendAnnouncementDraftSuccess = payload => {
  return {
    type: ANNOUNCEMENT_SEND_ANNOUNCEMENT_DRAFT_SUCCESS,
    payload: payload
  };
};

export const announcementSendAnnouncementDraftFailure = payload => {
  return {
    type: ANNOUNCEMENT_SEND_ANNOUNCEMENT_DRAFT_FAILURE,
    payload: payload
  };
};

export const announcementDraftAnnouncement = payload => {
  return {
    type: ANNOUNCEMENT_DRAFT_ANNOUNCEMENT,
    payload: payload
  };
};

export const announcementDraftAnnouncementSuccess = payload => {
  return {
    type: ANNOUNCEMENT_DRAFT_ANNOUNCEMENT_SUCCESS,
    payload: payload
  };
};

export const announcementDraftAnnouncementFailure = payload => {
  return {
    type: ANNOUNCEMENT_DRAFT_ANNOUNCEMENT_FAILURE,
    payload: payload
  };
};

export const announcementUpdateAnnouncement = payload => {
  return {
    type: ANNOUNCEMENT_UPDATE_ANNOUNCEMENT,
    payload: payload
  };
};

export const announcementUpdateAnnouncementSuccess = payload => {
  return {
    type: ANNOUNCEMENT_UPDATE_ANNOUNCEMENT_SUCCESS,
    payload: payload
  };
};

export const announcementUpdateAnnouncementFailure = payload => {
  return {
    type: ANNOUNCEMENT_UPDATE_ANNOUNCEMENT_FAILURE,
    payload: payload
  };
};

export const announcementUpdateAnnouncementDraft = payload => {
  return {
    type: ANNOUNCEMENT_UPDATE_ANNOUNCEMENT_DRAFT,
    payload: payload
  };
};

export const announcementUpdateAnnouncementDraftSuccess = payload => {
  return {
    type: ANNOUNCEMENT_UPDATE_ANNOUNCEMENT_DRAFT_SUCCESS,
    payload: payload
  };
};

export const announcementUpdateAnnouncementDraftFailure = payload => {
  return {
    type: ANNOUNCEMENT_UPDATE_ANNOUNCEMENT_DRAFT_FAILURE,
    payload: payload
  };
};

export const announcementDeleteAnnouncementSent = payload => {
  return {
    type: ANNOUNCEMENT_DELETE_ANNOUNCEMENT_SENT,
    payload: payload
  };
};

export const announcementDeleteAnnouncementSentSuccess = payload => {
  return {
    type: ANNOUNCEMENT_DELETE_ANNOUNCEMENT_SENT_SUCCESS,
    payload: payload
  };
};

export const announcementDeleteAnnouncementSentFailure = payload => {
  return {
    type: ANNOUNCEMENT_DELETE_ANNOUNCEMENT_SENT_FAILURE,
    payload: payload
  };
};

export const announcementDeleteAnnouncementDraft = payload => {
  return {
    type: ANNOUNCEMENT_DELETE_ANNOUNCEMENT_DRAFT,
    payload: payload
  };
};

export const announcementDeleteAnnouncementDraftSuccess = payload => {
  return {
    type: ANNOUNCEMENT_DELETE_ANNOUNCEMENT_DRAFT_SUCCESS,
    payload: payload
  };
};

export const announcementDeleteAnnouncementDraftFailure = payload => {
  return {
    type: ANNOUNCEMENT_DELETE_ANNOUNCEMENT_DRAFT_FAILURE,
    payload: payload
  };
};

export const announcementFetchAnnouncement = payload => {
  return {
    type: ANNOUNCEMENT_FETCH_ANNOUNCEMENT,
    payload: payload
  };
};

export const announcementFetchAnnouncementSuccess = payload => {
  return {
    type: ANNOUNCEMENT_FETCH_ANNOUNCEMENT_SUCCESS,
    payload: payload
  };
};

export const announcementFetchAnnouncementFailure = payload => {
  return {
    type: ANNOUNCEMENT_FETCH_ANNOUNCEMENT_FAILURE,
    payload: payload
  };
};

export const announcementSyncAnnouncementListRecent = payload => {
  return {
    type: ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_RECENT,
    payload: payload
  };
};

export const announcementUnSyncAnnouncementListRecent = payload => {
  return {
    type: ANNOUNCEMENT_UNSYNC_ANNOUNCEMENT_LIST_RECENT,
    payload: payload
  };
};

export const announcementSyncAnnouncementListRecentSuccess = payload => {
  return {
    type: ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_RECENT_SUCCESS,
    payload: payload
  };
};

export const announcementSyncAnnouncementListRecentFailure = payload => {
  return {
    type: ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_RECENT_FAILURE,
    payload: payload
  };
};

export const announcementSyncAnnouncementListSent = payload => {
  return {
    type: ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_SENT,
    payload: payload
  };
};

export const announcementUnSyncAnnouncementListSent = payload => {
  return {
    type: ANNOUNCEMENT_UNSYNC_ANNOUNCEMENT_LIST_SENT,
    payload: payload
  };
};

export const announcementSyncAnnouncementListSentSuccess = payload => {
  return {
    type: ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_SENT_SUCCESS,
    payload: payload
  };
};

export const announcementSyncAnnouncementListSentFailure = payload => {
  return {
    type: ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_SENT_FAILURE,
    payload: payload
  };
};

export const announcementSyncAnnouncementListDraft = payload => {
  return {
    type: ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_DRAFT,
    payload: payload
  };
};

export const announcementUnSyncAnnouncementListDraft = payload => {
  return {
    type: ANNOUNCEMENT_UNSYNC_ANNOUNCEMENT_LIST_DRAFT,
    payload: payload
  };
};

export const announcementSyncAnnouncementListDraftSuccess = payload => {
  return {
    type: ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_DRAFT_SUCCESS,
    payload: payload
  };
};

export const announcementSyncAnnouncementListDraftFailure = payload => {
  return {
    type: ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_DRAFT_FAILURE,
    payload: payload
  };
};
