// Settings
export const TOGGLE_COLLAPSED_NAV = "toggle_collapse_menu";
export const DRAWER_TYPE = "drawer_type";
export const FIXED_DRAWER = "fixed_drawer";
export const COLLAPSED_DRAWER = "collapsible";
export const MINI_DRAWER = "mini_drawer";
export const THEME_COLOR = "theme_color";
export const DARK_THEME = "dark_theme";
export const WINDOW_WIDTH = "window-width";
export const SWITCH_LANGUAGE = "switch-language";
export const CHANGE_DIRECTION = "change-direction";

export const CHANGE_NAVIGATION_STYLE = "change-navigation-style";
export const HORIZONTAL_NAVIGATION = "horizontal_navigation";
export const VERTICAL_NAVIGATION = "vertical_navigation";

export const HORIZONTAL_MENU_POSITION = "horizontal_menu_position";
export const ABOVE_THE_HEADER = "above_the_header";
export const INSIDE_THE_HEADER = "inside_the_header";
export const BELOW_THE_HEADER = "below_the_header";

export const ON_HIDE_LOADER = "on_hide_loader";
export const ON_SHOW_LOADER = "on_show_loader";
export const INIT_URL = "init_url";

// SystemSettings
export const SHOW_ALERT = "show_alert";
export const HIDE_ALERT = "hide_alert";

//TODO: NAMING NOTES
// LOAD -> takes data from synced data list.
// FETCH -> pulls data once from DB via saga.
// SYNC -> pulls data on every change from DB via saga.
// UNSYNC -> cancels sync.
// UPDATE -> Updates node in DB with data.
// CREATE -> Adds new node/data in DB.
// DELETE -> Removes node/data from DB.
// ARCHIVE -> Changes/moves data to archived status.
// ACTIVATE -> Changes/moves data to active status.

// AUTH
export const AUTH_STATUS = "auth_status";

export const AUTH_WATCHER = "auth_watcher";
export const AUTH_WATCHER_SUCCESS = "auth_watcher_success";
export const AUTH_WATCHER_FAILURE = "auth_watcher_failure";

export const AUTH_LOGOUT = "auth_logout";
export const AUTH_LOGOUT_SUCCESS = "auth_logout_success";

export const AUTH_LOGIN = "auth_login";
export const AUTH_LOGIN_SUCCESS = "auth_login_success";
export const AUTH_LOGIN_FAILURE = "auth_login_failure";

export const AUTH0_LOGIN = "auth0_login";
export const AUTH0_LOGIN_SUCCESS = "auth0_login_success";
export const AUTH0_LOGIN_FAILURE = "auth0_login_failure";

export const FIREBASE_LOGIN = "firebase_login";
export const FIREBASE_LOGIN_SUCCESS = "firebase_login_success";
export const FIREBASE_LOGIN_FAILURE = "firebase_login_failure";

export const AUTH_REQUEST_USER_ID_TOKEN = "auth_request_user_id_token";

export const AUTH_ADD_INVITECODE = "auth_add_invitecode";
export const AUTH_ADD_INVITECODE_SUCCESS = "auth_add_invitecode_success";
export const AUTH_ADD_INVITECODE_FAILURE = "auth_add_invitecode_failure";

export const AUTH_REAUTH_USER = "auth_reauth_user";
export const AUTH_REAUTH_USER_SUCCESS = "auth_reauth_user_success";
export const AUTH_REAUTH_USER_FAILURE = "auth_reauth_user_failure";

export const AUTH_REAUTH_USER_REQUIRE = "auth_reauth_user_require";

export const AUTH_CLEAR_ERROR = "auth_clear_error";

export const AUTH_CHANGE_PASSWORD = "auth_change_password";
export const AUTH_CHANGE_PASSWORD_SUCCESS = "auth_change_password_success";
export const AUTH_CHANGE_PASSWORD_FAILURE = "auth_change_password_failure";

export const AUTH_REQUEST_PASSWORD_RESET = "auth_reguest_password_reset";
export const AUTH_REQUEST_PASSWORD_RESET_SUCCESS = "auth_reguest_password_reset_success";
export const AUTH_REQUEST_PASSWORD_RESET_FAILURE = "auth_reguest_password_reset_failure";

export const AUTH_CHANGE_USER_LANGUAGE = "auth_change_user_language";
export const AUTH_CHANGE_USER_LANGUAGE_SUCCESS = "auth_change_user_language_success";
export const AUTH_CHANGE_USER_LANGUAGE_FAILURE = "auth_change_user_language_failure";

export const AUTH_SIGNUP = "auth_signup";
export const AUTH_SIGNUP_SUCCESS = "auth_signup_success";
export const AUTH_SIGNUP_FAILURE = "auth_signup_failure";

export const AUTH_SIGNUP_SET_INVITECODE = "auth_signup_set_invitecode";
export const AUTH_SIGNUP_PROVIDER_PASSWORD_SET_EMAIL = "auth_signup_provider_password_set_email";
export const AUTH_SIGNUP_PROVIDER_PASSWORD_SET_PASSWORD = "auth_signup_provider_password_set_password";
export const AUTH_SIGNUP_PROVIDER_PASSWORD_SET_PROFILE = "auth_signup_provider_password_set_profile";
export const AUTH_SIGNUP_PROVIDER_PASSWORD = "auth_signup_provider_password";
export const AUTH_SIGNUP_PROVIDER_PASSWORD_SUCCESS = "auth_signup_provider_password_success";
export const AUTH_SIGNUP_PROVIDER_PASSWORD_FAILURE = "auth_signup_provider_password_failure";

export const AUTH_FETCH_USER_PROFILE = "auth_fetch_user_profile";
export const AUTH_FETCH_USER_PROFILE_SUCCESS = "auth_fetch_user_profile_success";
export const AUTH_FETCH_USER_PROFILE_FAILURE = "auth_fetch_user_profile_failure";

export const AUTH_UPDATE_USER_PROFILE = "auth_fetch_user_profile";
export const AUTH_UPDATE_USER_PROFILE_SUCCESS = "auth_fetch_user_profile_success";
export const AUTH_UPDATE_USER_PROFILE_FAILURE = "auth_fetch_user_profile_failure";

export const AUTH_FETCH_USER_SETTINGS = "auth_fetch_user_settings";
export const AUTH_FETCH_USER_SETTINGS_SUCCESS = "auth_fetch_user_settings_success";
export const AUTH_FETCH_USER_SETTINGS_FAILURE = "auth_fetch_user_settings_failure";

export const AUTH_UPDATE_USER_SETTINGS = "auth_fetch_user_settings";
export const AUTH_UPDATE_USER_SETTINGS_SUCCESS = "auth_fetch_user_settings_success";
export const AUTH_UPDATE_USER_SETTINGS_FAILURE = "auth_fetch_user_settings_failure";

export const AUTH_SYNC_USER_METADATA = "auth_sync_user_metadata";
export const AUTH_SYNC_USER_METADATA_SUCCESS = "auth_sync_user_metadata_success";
export const AUTH_SYNC_USER_METADATA_FAILURE = "auth_sync_user_metadata_failure";

export const AUTH_UPDATE_USER_PUSH_NOTIFICATION_TOKEN = "auth_update_user_push_notification_token";
export const AUTH_UPDATE_USER_PUSH_NOTIFICATION_TOKEN_SUCCESS = "auth_update_user_push_notification_token_success";
export const AUTH_UPDATE_USER_PUSH_NOTIFICATION_TOKEN_FAILURE = "auth_update_user_push_notification_token_failure";

//PROFILE
export const PROFILE_FETCH_USER_PROFILE = "profile_fetch_user_profile";
export const PROFILE_FETCH_USER_PROFILE_SUCCESS = "profile_fetch_user_profile_success";
export const PROFILE_FETCH_USER_PROFILE_FAILURE = "profile_fetch_user_profile_failure";
export const PROFILE_UPDATE_USER_PROFILE = "profile_update_user_profile";
export const PROFILE_UPDATE_USER_PROFILE_SUCCESS = "profile_update_user_profile_success";
export const PROFILE_UPDATE_USER_PROFILE_FAILURE = "profile_update_user_profile_failure";

// SCHOOL
export const SCHOOL_FETCH_SCHOOL_PROFILE = "school_fetch_school_profile";
export const SCHOOL_FETCH_SCHOOL_PROFILE_SUCCESS = "school_fetch_school_profile_success";
export const SCHOOL_FETCH_SCHOOL_PROFILE_FAILURE = "school_fetch_school_profile_failure";
export const SCHOOL_UPDATE_SCHOOL_PROFILE = "school_update_school_profile";
export const SCHOOL_UPDATE_SCHOOL_PROFILE_SUCCESS = "school_update_school_profile_success";
export const SCHOOL_UPDATE_SCHOOL_PROFILE_FAILURE = "school_update_school_profile_failure";

export const SCHOOL_FETCH_SCHOOL_SETTINGS = "school_fetch_school_settings";
export const SCHOOL_FETCH_SCHOOL_SETTINGS_SUCCESS = "school_fetch_school_settings_success";
export const SCHOOL_FETCH_SCHOOL_SETTINGS_FAILURE = "school_fetch_school_settings_failure";

export const SCHOOL_FETCH_SCHOOL_USERS = "school_fetch_school_users";
export const SCHOOL_FETCH_SCHOOL_USERS_SUCCESS = "school_fetch_school_users_success";
export const SCHOOL_FETCH_SCHOOL_USERS_FAILURE = "school_fetch_school_users_failure";

export const SCHOOL_LISTENER_ON_SCHOOL_USERS = "school_listener_on_school_users";
export const SCHOOL_LISTENER_OFF_SCHOOL_USERS = "school_listener_off_school_users";
export const SCHOOL_LISTENER_EVENT_SCHOOL_USERS = "school_listener_event_school_users";

export const SCHOOL_SYNC_SCHOOL_METADATA = "school_sync_school_metadata";
export const SCHOOL_SYNC_SCHOOL_METADATA_SUCCESS = "school_sync_school_metadata_success";
export const SCHOOL_SYNC_SCHOOL_METADATA_FAILURE = "school_sync_school_metadata_failure";

export const SCHOOL_ACADEMICYEAR_FETCH_SCHOOL_ACADEMICYEAR_LIST = "school_academicYear_fetch_school_academicYear_list";
export const SCHOOL_ACADEMICYEAR_FETCH_SCHOOL_ACADEMICYEAR_LIST_SUCCESS = "school_academicYear_fetch_school_academicYear_list_success";
export const SCHOOL_ACADEMICYEAR_FETCH_SCHOOL_ACADEMICYEAR_LIST_FAILURE = "school_academicYear_fetch_school_academicYear_list_failure";

export const SCHOOL_ACADEMICYEAR_SYNC_SCHOOL_ACADEMICYEAR_LIST = "school_academicYear_sync_school_academicYear_list";
export const SCHOOL_ACADEMICYEAR_SYNC_SCHOOL_ACADEMICYEAR_LIST_SUCCESS = "school_academicYear_sync_school_academicYear_list_success";
export const SCHOOL_ACADEMICYEAR_SYNC_SCHOOL_ACADEMICYEAR_LIST_FAILURE = "school_academicYear_sync_school_academicYear_list_failure";

export const SCHOOL_ACADEMICYEAR_UNSYNC_SCHOOL_ACADEMICYEAR_LIST = "school_academicYear_unsync_school_academicYear_list";

export const SCHOOL_ACADEMICYEAR_ADD_SCHOOL_ACADEMICYEAR = "school_academicYear_add_school_academicYear";
export const SCHOOL_ACADEMICYEAR_ADD_SCHOOL_ACADEMICYEAR_SUCCESS = "school_academicYear_add_school_academicYear_success";
export const SCHOOL_ACADEMICYEAR_ADD_SCHOOL_ACADEMICYEAR_FAILURE = "school_academicYear_add_school_academicYear_failure";

export const SCHOOL_ACADEMICYEAR_UPDATE_SCHOOL_ACADEMICYEAR = "school_academicYear_update_school_academicYear";
export const SCHOOL_ACADEMICYEAR_UPDATE_SCHOOL_ACADEMICYEAR_SUCCESS = "school_academicYear_update_school_academicYear_success";
export const SCHOOL_ACADEMICYEAR_UPDATE_SCHOOL_ACADEMICYEAR_FAILURE = "school_academicYear_update_school_academicYear_failure";

export const SCHOOL_ACADEMICYEAR_DELETE_SCHOOL_ACADEMICYEAR = "school_academicYear_delete_school_academicYear";
export const SCHOOL_ACADEMICYEAR_DELETE_SCHOOL_ACADEMICYEAR_SUCCESS = "school_academicYear_delete_school_academicYear_success";
export const SCHOOL_ACADEMICYEAR_DELETE_SCHOOL_ACADEMICYEAR_FAILURE = "school_academicYear_delete_school_academicYear_failure";

export const SCHOOL_ROOM_FETCH_ROOM_LIST = "school_room_fetch_room_list";
export const SCHOOL_ROOM_FETCH_ROOM_LIST_SUCCESS = "school_room_fetch_room_list_success";
export const SCHOOL_ROOM_FETCH_ROOM_LIST_FAILURE = "school_room_fetch_room_list_failure";

export const SCHOOL_ROOM_ADD_ROOM = "school_room_add_room";
export const SCHOOL_ROOM_ADD_ROOM_SUCCESS = "school_room_add_room_success";
export const SCHOOL_ROOM_ADD_ROOM_FAILURE = "school_room_add_room_failure";

export const SCHOOL_ROOM_UPDATE_ROOM = "school_room_update_room";
export const SCHOOL_ROOM_UPDATE_ROOM_SUCCESS = "school_room_update_room_success";
export const SCHOOL_ROOM_UPDATE_ROOM_FAILURE = "school_room_update_room_failure";

export const SCHOOL_ROOM_DELETE_ROOM_LIST = "school_room_delete_room_list";
export const SCHOOL_ROOM_DELETE_ROOM_LIST_SUCCESS = "school_room_delete_room_list_success";
export const SCHOOL_ROOM_DELETE_ROOM_LIST_FAILURE = "school_room_delete_room_list_failure";

export const SCHOOL_ROOM_SYNC_ROOM_LIST = "school_room_sync_room_list";
export const SCHOOL_ROOM_SYNC_ROOM_LIST_SUCCESS = "school_room_sync_room_list_success";
export const SCHOOL_ROOM_SYNC_ROOM_LIST_FAILURE = "school_room_sync_room_list_failure";

export const SCHOOL_ROOM_UNSYNC_ROOM_LIST = "school_room_unsync_room_list";

// CLASS
export const CLASS_FETCH_CLASS = "class_fetch_class";
export const CLASS_FETCH_CLASS_SUCCESS = "class_fetch_class_success";
export const CLASS_FETCH_CLASS_FAILURE = "class_fetch_class_failure";

export const CLASS_LOAD_CLASS = "class_load_class";
export const CLASS_LOAD_CLASS_SUCCESS = "class_load_class_success";
export const CLASS_LOAD_CLASS_FAILURE = "class_load_class_failure";

export const CLASS_CREATE_CLASS = "class_create_class";
export const CLASS_CREATE_CLASS_SUCCESS = "class_create_class_success";
export const CLASS_CREATE_CLASS_FAILURE = "class_create_class_failure";

export const CLASS_UPDATE_CLASS = "class_update_class";
export const CLASS_UPDATE_CLASS_SUCCESS = "class_update_class_success";
export const CLASS_UPDATE_CLASS_FAILURE = "class_update_class_failure";

export const CLASS_DELETE_CLASS = "class_delete_class";
export const CLASS_DELETE_CLASS_SUCCESS = "class_delete_class_success";
export const CLASS_DELETE_CLASS_FAILURE = "class_delete_class_failure";

export const CLASS_ARCHIVE_CLASS = "class_archive_class";
export const CLASS_ARCHIVE_CLASS_SUCCESS = "class_archive_class_success";
export const CLASS_ARCHIVE_CLASS_FAILURE = "class_archive_class_failure";

export const CLASS_UNARCHIVE_CLASS = "class_unarchive_class";
export const CLASS_UNARCHIVE_CLASS_SUCCESS = "class_unarchive_class_success";
export const CLASS_UNARCHIVE_CLASS_FAILURE = "class_unarchive_class_failure";

export const CLASS_FETCH_CLASS_LIST_TODAY = "class_fetch_class_list_today";
export const CLASS_FETCH_CLASS_LIST_TODAY_SUCCESS = "class_fetch_class_list_today_success";
export const CLASS_FETCH_CLASS_LIST_TODAY_FAILURE = "class_fetch_class_list_today_failure";

export const CLASS_FETCH_CLASS_LIST_ACTIVE = "class_fetch_class_list_active";
export const CLASS_FETCH_CLASS_LIST_ACTIVE_SUCCESS = "class_fetch_class_list_active_success";
export const CLASS_FETCH_CLASS_LIST_ACTIVE_FAILURE = "class_fetch_class_list_active_failure";

export const CLASS_SYNC_CLASS_LIST_ACTIVE = "class_sync_class_list_active";
export const CLASS_SYNC_CLASS_LIST_ACTIVE_SUCCESS = "class_sync_class_list_active_success";
export const CLASS_SYNC_CLASS_LIST_ACTIVE_FAILURE = "class_sync_class_list_active_failure";

export const CLASS_UNSYNC_CLASS_LIST_ACTIVE = "class_unsync_class_list_active";

export const CLASS_SYNC_CLASS_LIST_ARCHIVED = "class_sync_class_list_archived";
export const CLASS_SYNC_CLASS_LIST_ARCHIVED_SUCCESS = "class_sync_class_list_archived_success";
export const CLASS_SYNC_CLASS_LIST_ARCHIVED_FAILURE = "class_sync_class_list_archived_failure";

export const CLASS_UNSYNC_CLASS_LIST_ARCHIVED = "class_unsync_class_list_archived";

export const CLASS_SYNC_CLASS_STUDENT_LIST = "class_sync_class_student_list";
export const CLASS_SYNC_CLASS_STUDENT_LIST_SUCCESS = "class_sync_class_student_list_success";
export const CLASS_SYNC_CLASS_STUDENT_LIST_FAILURE = "class_sync_class_student_list_failure";

export const CLASS_UNSYNC_CLASS_STUDENT_LIST = "class_unsync_class_student_list";

//STUDENT
export const STUDENT_SHOW_ALERT_MESSAGE = "student_show_alert_message";
export const STUDENT_HIDE_ALERT_MESSAGE = "student_hide_alert_message";

export const STUDENT_LOAD_SELECTED_STUDENT = "student_load_selected_student";

export const STUDENT_FETCH_SELECTED_STUDENT = "student_fetch_selected_student";
export const STUDENT_FETCH_SELECTED_STUDENT_SUCCESS = "student_fetch_selected_student_success";
export const STUDENT_FETCH_SELECTED_STUDENT_FAILURE = "student_fetch_selected_student_failure";

export const STUDENT_SYNC_SELECTED_STUDENT = "student_sync_selected_student";
export const STUDENT_SYNC_SELECTED_STUDENT_SUCCESS = "student_sync_selected_student_success";
export const STUDENT_SYNC_SELECTED_STUDENT_FAILURE = "student_sync_selected_student_failure";

export const STUDENT_UNSYNC_SELECTED_STUDENT = "student_unsync_selected_student";

export const STUDENT_FETCH_STUDENT_LIST_ACTIVE = "student_fetch_student_list_active";
export const STUDENT_FETCH_STUDENT_LIST_ACTIVE_SUCCESS = "student_fetch_student_list_active_success";
export const STUDENT_FETCH_STUDENT_LIST_ACTIVE_FAILURE = "student_fetch_student_list_active_failure";

export const STUDENT_FETCH_STUDENT_LIST_ARCHIVED = "student_fetch_student_list_archived";
export const STUDENT_FETCH_STUDENT_LIST_ARCHIVED_SUCCESS = "student_fetch_student_list_archived_success";
export const STUDENT_FETCH_STUDENT_LIST_ARCHIVED_FAILURE = "student_fetch_student_list_archived_failure";

export const STUDENT_SYNC_STUDENT_LIST_ACTIVE = "student_sync_student_list_active";
export const STUDENT_SYNC_STUDENT_LIST_ACTIVE_SUCCESS = "student_sync_student_list_active_success";
export const STUDENT_SYNC_STUDENT_LIST_ACTIVE_FAILURE = "student_sync_student_list_active_failure";

export const STUDENT_UNSYNC_STUDENT_LIST_ACTIVE = "student_unsync_student_list_active";

export const STUDENT_SYNC_STUDENT_LIST_ARCHIVED = "student_sync_student_list_archived";
export const STUDENT_SYNC_STUDENT_LIST_ARCHIVED_SUCCESS = "student_sync_student_list_archived_success";
export const STUDENT_SYNC_STUDENT_LIST_ARCHIVED_FAILURE = "student_sync_student_list_archived_failure";

export const STUDENT_UNSYNC_STUDENT_LIST_ARCHIVED = "student_unsync_student_list_archived";

export const STUDENT_DELETE_STUDENTS = "student_delete_students";
export const STUDENT_DELETE_STUDENTS_SUCCESS = "student_delete_students_success";
export const STUDENT_DELETE_STUDENTS_FAILURE = "student_delete_students_failure";

export const STUDENT_ARCHIVE_STUDENTS = "student_archive_students";
export const STUDENT_ARCHIVE_STUDENTS_SUCCESS = "student_archive_students_success";
export const STUDENT_ARCHIVE_STUDENTS_FAILURE = "student_archive_students_failure";

export const STUDENT_UNARCHIVE_STUDENTS = "student_unarchive_students";
export const STUDENT_UNARCHIVE_STUDENTS_SUCCESS = "student_unarchive_students_success";
export const STUDENT_UNARCHIVE_STUDENTS_FAILURE = "student_unarchive_students_failure";

export const STUDENT_UPDATE_STUDENT = "student_update_students";
export const STUDENT_UPDATE_STUDENT_SUCCESS = "student_update_students_success";
export const STUDENT_UPDATE_STUDENT_FAILURE = "student_update_students_failure";

export const STUDENT_CREATE_STUDENT = "student_create_student";
export const STUDENT_CREATE_STUDENT_SUCCESS = "student_create_student_success";
export const STUDENT_CREATE_STUDENT_FAILURE = "student_create_student_failure";



export const STUDENT_FETCH_SELECTED_STUDENT_PARENT_LIST = "student_fetch_selected_student_parent_list";
export const STUDENT_FETCH_SELECTED_STUDENT_PARENT_LIST_SUCCESS = "student_fetch_selected_student_parent_list_success";
export const STUDENT_FETCH_SELECTED_STUDENT_PARENT_LIST_FAILURE = "student_fetch_selected_student_parent_list_failure";


//ATTENDANCE
export const ATTENDANCE_ADD_ATTENDANCE = "attendance_add_attendance";
export const ATTENDANCE_ADD_ATTENDANCE_SUCCESS = "attendance_add_attendance_success";
export const ATTENDANCE_ADD_ATTENDANCE_FAILURE = "attendance_add_attendance_failure";

export const ATTENDANCE_UPDATE_ATTENDANCE = "attendance_update_attendance";
export const ATTENDANCE_UPDATE_ATTENDANCE_SUCCESS = "attendance_update_attendance_success";
export const ATTENDANCE_UPDATE_ATTENDANCE_FAILURE = "attendance_update_attendance_failure";

export const ATTENDANCE_DELETE_ATTENDANCE = "attendance_delete_attendance";
export const ATTENDANCE_DELETE_ATTENDANCE_SUCCESS = "attendance_delete_attendance_success";
export const ATTENDANCE_DELETE_ATTENDANCE_FAILURE = "attendance_delete_attendance_failure";

export const ATTENDANCE_SYNC_ATTENDANCE_LIST = "attendance_sync_attendance_list";
export const ATTENDANCE_SYNC_ATTENDANCE_LIST_SUCCESS = "attendance_sync_attendance_list_success";
export const ATTENDANCE_SYNC_ATTENDANCE_LIST_FAILURE = "attendance_sync_attendance_list_failure";

export const ATTENDANCE_UNSYNC_ATTENDANCE_LIST = "attendance_unsync_attendance_list";

export const ATTENDANCE_SYNC_ATTENDANCE_LIST_BY_DATE_AND_TYPE = "attendance_sync_attendance_list_by_date_and_type";
export const ATTENDANCE_SYNC_ATTENDANCE_LIST_BY_DATE_AND_TYPE_SUCCESS = "attendance_sync_attendance_list_by_date_and_type_success";
export const ATTENDANCE_SYNC_ATTENDANCE_LIST_BY_DATE_AND_TYPE_FAILURE = "attendance_sync_attendance_list_by_date_and_type_failure";

export const ATTENDANCE_UNSYNC_ATTENDANCE_LIST_BY_DATE_AND_TYPE = "attendance_unsync_attendance_list_by_date_and_type";

//ANNOUNCEMENT
export const ANNOUNCEMENT_FETCH_ANNOUNCEMENT = "announcement_fetch_announcement";
export const ANNOUNCEMENT_FETCH_ANNOUNCEMENT_SUCCESS = "announcement_fetch_announcement_success";
export const ANNOUNCEMENT_FETCH_ANNOUNCEMENT_FAILURE = "announcement_fetch_announcement_failure";

export const ANNOUNCEMENT_SEND_ANNOUNCEMENT = "announcement_send_announcement";
export const ANNOUNCEMENT_SEND_ANNOUNCEMENT_SUCCESS = "announcement_send_announcement_success";
export const ANNOUNCEMENT_SEND_ANNOUNCEMENT_FAILURE = "announcement_send_announcement_failure";

export const ANNOUNCEMENT_SEND_ANNOUNCEMENT_DRAFT = "announcement_send_announcement_draft";
export const ANNOUNCEMENT_SEND_ANNOUNCEMENT_DRAFT_SUCCESS = "announcement_send_announcement_draft_success";
export const ANNOUNCEMENT_SEND_ANNOUNCEMENT_DRAFT_FAILURE = "announcement_send_announcement_draft_failure";

export const ANNOUNCEMENT_DRAFT_ANNOUNCEMENT = "announcement_draft_announcement";
export const ANNOUNCEMENT_DRAFT_ANNOUNCEMENT_SUCCESS = "announcement_draft_announcement_success";
export const ANNOUNCEMENT_DRAFT_ANNOUNCEMENT_FAILURE = "announcement_draft_announcement_failure";

export const ANNOUNCEMENT_UPDATE_ANNOUNCEMENT = "announcement_update_announcement";
export const ANNOUNCEMENT_UPDATE_ANNOUNCEMENT_SUCCESS = "announcement_update_announcement_success";
export const ANNOUNCEMENT_UPDATE_ANNOUNCEMENT_FAILURE = "announcement_update_announcement_failure";

export const ANNOUNCEMENT_UPDATE_ANNOUNCEMENT_DRAFT = "announcement_update_announcement_draft";
export const ANNOUNCEMENT_UPDATE_ANNOUNCEMENT_DRAFT_SUCCESS = "announcement_update_announcement_draft_success";
export const ANNOUNCEMENT_UPDATE_ANNOUNCEMENT_DRAFT_FAILURE = "announcement_update_announcement_draft_failure";

export const ANNOUNCEMENT_DELETE_ANNOUNCEMENT_SENT = "announcement_delete_announcement_sent";
export const ANNOUNCEMENT_DELETE_ANNOUNCEMENT_SENT_SUCCESS = "announcement_delete_announcement_sent_success";
export const ANNOUNCEMENT_DELETE_ANNOUNCEMENT_SENT_FAILURE = "announcement_delete_announcement_sent_failure";

export const ANNOUNCEMENT_DELETE_ANNOUNCEMENT_DRAFT = "announcement_delete_announcement_draft";
export const ANNOUNCEMENT_DELETE_ANNOUNCEMENT_DRAFT_SUCCESS = "announcement_delete_announcement_draft_success";
export const ANNOUNCEMENT_DELETE_ANNOUNCEMENT_DRAFT_FAILURE = "announcement_delete_announcement_draft_failure";

export const ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_RECENT = "announcement_sync_announcement_list_recent";
export const ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_RECENT_SUCCESS = "announcement_sync_announcement_list_recent_success";
export const ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_RECENT_FAILURE = "announcement_sync_announcement_list_recent_failure";
export const ANNOUNCEMENT_UNSYNC_ANNOUNCEMENT_LIST_RECENT = "announcement_unsync_announcement_list_recent";

export const ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_SENT = "announcement_sync_announcement_list_sent";
export const ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_SENT_SUCCESS = "announcement_sync_announcement_list_sent_success";
export const ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_SENT_FAILURE = "announcement_sync_announcement_list_sent_failure";
export const ANNOUNCEMENT_UNSYNC_ANNOUNCEMENT_LIST_SENT = "announcement_unsync_announcement_list_sent";

export const ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_DRAFT = "announcement_sync_announcement_list_draft";
export const ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_DRAFT_SUCCESS = "announcement_sync_announcement_list_draft_success";
export const ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_DRAFT_FAILURE = "announcement_sync_announcement_list_draft_failure";
export const ANNOUNCEMENT_UNSYNC_ANNOUNCEMENT_LIST_DRAFT = "announcement_unsync_announcement_list_draft";

// SCHOOL_CALENDAR
export const SCHOOL_CALENDAR_CREATE_EVENT = "school_calendar_create_event";
export const SCHOOL_CALENDAR_CREATE_EVENT_SUCCESS = "school_calendar_create_event_success";
export const SCHOOL_CALENDAR_CREATE_EVENT_FAILURE = "school_calendar_create_event_failure";

export const SCHOOL_CALENDAR_UPDATE_EVENT = "school_calendar_update_event";
export const SCHOOL_CALENDAR_UPDATE_EVENT_SUCCESS = "school_calendar_update_event_success";
export const SCHOOL_CALENDAR_UPDATE_EVENT_FAILURE = "school_calendar_update_event_failure";

export const SCHOOL_CALENDAR_DELETE_EVENT = "school_calendar_delete_event";
export const SCHOOL_CALENDAR_DELETE_EVENT_SUCCESS = "school_calendar_delete_event_success";
export const SCHOOL_CALENDAR_DELETE_EVENT_FAILURE = "school_calendar_delete_event_failure";

export const SCHOOL_CALENDAR_FETCH_EVENT_LIST_BY_DATE = "school_calendar_fetch_event_list_by_date";
export const SCHOOL_CALENDAR_FETCH_EVENT_LIST_BY_DATE_SUCCESS = "school_calendar_fetch_event_list_by_date_success";
export const SCHOOL_CALENDAR_FETCH_EVENT_LIST_BY_DATE_FAILURE = "school_calendar_fetch_event_list_by_date_failure";

export const SCHOOL_CALENDAR_SYNC_EVENT_LIST_BY_ACADEMIC_YEAR_ID = "school_calendar_sync_event_list_by_academic_year_id";
export const SCHOOL_CALENDAR_SYNC_EVENT_LIST_BY_ACADEMIC_YEAR_ID_SUCCESS = "school_calendar_sync_event_list_by_academic_year_id_success";
export const SCHOOL_CALENDAR_SYNC_EVENT_LIST_BY_ACADEMIC_YEAR_ID_FAILURE = "school_calendar_sync_event_list_by_academic_year_id_failure";

export const SCHOOL_CALENDAR_UNSYNC_EVENT_LIST_BY_ACADEMIC_YEAR_ID = "school_calendar_unsync_event_list_by_academic_year_id";

// SCHOOL_MESSENGER

export const SCHOOL_MESSENGER_SYNC_CONVERSATION_LIST = "school_messenger_sync_conversation_list";
export const SCHOOL_MESSENGER_SYNC_CONVERSATION_LIST_SUCCESS = "school_messenger_sync_conversation_list_success";
export const SCHOOL_MESSENGER_SYNC_CONVERSATION_LIST_FAILURE = "school_messenger_ync_conversation_list_failure";
export const SCHOOL_MESSENGER_UNSYNC_CONVERSATION_LIST = "school_messenger_unsync_conversation_list";
export const SCHOOL_MESSENGER_ON_SCROLL_CONVERSATION_LIST = "school_messenger_on_scroll_conversation_list";

export const SCHOOL_MESSENGER_FETCH_SELECTED_CONVERSATION = "school_messenger_fetch_selected_conversation";
export const SCHOOL_MESSENGER_FETCH_SELECTED_CONVERSATION_SUCCESS = "school_messenger_fetch_selected_conversation_success";
export const SCHOOL_MESSENGER_FETCH_SELECTED_CONVERSATION_FAILURE = "school_messenger_fetch_selected_conversation_failure";

export const SCHOOL_MESSENGER_FETCH_MORE_SELECTED_CONVERSATION = "school_messenger_fetch_more_selected_conversation";
export const SCHOOL_MESSENGER_FETCH_MORE_SELECTED_CONVERSATION_SUCCESS = "school_messenger_fetch_more_selected_conversation_success";
export const SCHOOL_MESSENGER_FETCH_MORE_SELECTED_CONVERSATION_FAILURE = "school_messenger_fetch_more_selected_conversation_failure";

export const SCHOOL_MESSENGER_ADD_NEW_MESSAGE_WATCHER_SELECTED_CONVERSATION = "school_messenger_add_new_message_watcher_selected_conversation";
export const SCHOOL_MESSENGER_ADD_NEW_MESSAGE_WATCHER_SELECTED_CONVERSATION_SUCCESS = "school_messenger_add_new_message_watcher_selected_conversation_success";
export const SCHOOL_MESSENGER_ADD_NEW_MESSAGE_WATCHER_SELECTED_CONVERSATION_FAILURE = "school_messenger_add_new_message_watcher_selected_conversation_failure";
export const SCHOOL_MESSENGER_REMOVE_NEW_MESSAGE_WATCHER_SELECTED_CONVERSATION = "school_messenger_remove_new_message_watcher_selected_conversation";





export const SCHOOL_MESSENGER_SYNC_SELECTED_CONVERSATION = "school_messenger_sync_selected_conversation";
export const SCHOOL_MESSENGER_SYNC_SELECTED_CONVERSATION_SUCCESS = "school_messenger_sync_selected_conversation_success";
export const SCHOOL_MESSENGER_SYNC_SELECTED_CONVERSATION_FAILURE = "school_messenger_sync_selected_conversation_failure";
export const SCHOOL_MESSENGER_UNSYNC_SELECTED_CONVERSATION = "school_messenger_unsync_selected_conversation";
export const SCHOOL_MESSENGER_ON_SCROLL_SELECTED_CONVERSATION = "school_messenger_on_scroll_selected_conversation";

export const SCHOOL_MESSENGER_SUBMIT_MESSAGE = "school_messenger_submit_message";
export const SCHOOL_MESSENGER_MARK_MESSAGES_AS_READ = "school_messenger_mark_messages_as_read";

// SCHOOL USERS

export const SCHOOL_USERS_FETCH_SCHOOL_USERS_LIST_ACTIVE = "school_users_fetch_school_users_list_active";
export const SCHOOL_USERS_FETCH_SCHOOL_USERS_LIST_ACTIVE_SUCCESS = "school_users_fetch_school_users_list_active_success";
export const SCHOOL_USERS_FETCH_SCHOOL_USERS_LIST_ACTIVE_FAILURE = "school_users_fetch_school_users_list_active_failure";



// POSTS

export const POSTS_DIALOG_ADD_OPEN = "posts_dialog_add_open";
export const POSTS_DIALOG_ADD_CLOSE = "posts_dialog_add_close";

export const POSTS_DIALOG_EDIT_OPEN = "posts_dialog_edit_open";
export const POSTS_DIALOG_EDIT_CLOSE = "posts_dialog_edit_close";

export const POSTS_FETCH_CURRENT_LIST = "posts_fetch_current_list";
export const POSTS_FETCH_CURRENT_LIST_SUCCESS = "posts__fetch_current_list_success";
export const POSTS_FETCH_CURRENT_LIST_FAILURE = "posts__fetch_current_list_failure";

export const POSTS_CREATE = "posts__create";
export const POSTS_CREATE_SUCCESS = "posts__create_success";
export const POSTS_CREATE_FAILURE = "posts__create_failure";

export const POSTS_UPDATE = "posts__update";
export const POSTS_UPDATE_SUCCESS = "posts__update_success";
export const POSTS_UPDATE_FAILURE = "posts_g_update_failure";

export const POSTS_DELETE = "posts__delete";
export const POSTS_DELETE_SUCCESS = "posts__delete_success";
export const POSTS_DELETE_FAILURE = "posts__delete_failure";


