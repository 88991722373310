import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
const rootEl = document.getElementById('app-site');

let render = () => {
  const MainApp = require('./MainApp').default;
  ReactDOM.render(
    <MainApp/>,
    rootEl
  );
};

if (module.hot) {
  module.hot.accept('./MainApp', () => {
    const MainApp = require('./MainApp').default;
    render(
      <MainApp/>,
      rootEl
    );
  });
}

render();
serviceWorker.register();