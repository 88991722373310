import React, {useEffect, useMemo, Suspense} from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import "assets/vendors/style";
import defaultTheme from "../themes/defaultTheme";
import AppLocale from "../languages";
import AppRouter from "routes/AppRouter";
import { auth0LoginSuccess } from "redux/actions/Auth";
import RTL from "util/RTL";
import { switchLanguage } from "redux/actions/Setting";
import moment from "moment-timezone";
import momentJa from "moment/locale/ja";
import momentOverides from "util/momentOverides";
import { ConnectedRouter } from "connected-react-router";
import { ReactReduxContext } from 'react-redux';
import { history } from "../redux/store";
import CacheBuster from '../CacheBuster';
import {Auth0Provider} from "@auth0/auth0-react";
import CircularProgress from "../components/CircularProgress";

export function App(props) {
  const { locale, auth, loaded } = props;
  const applyTheme = createMuiTheme(defaultTheme);
  const localeData = useMemo(
      () => AppLocale[locale.locale],
      [locale.locale]
  );
  useEffect(() => {
    if(locale.locale === 'ja'){
      moment.updateLocale(locale.locale, momentJa);
      moment.updateLocale(locale.locale, momentOverides[locale.locale]);
    }
    document.documentElement.setAttribute("lang", locale.locale);
  }, [locale.locale]);

  return(
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
          return (
              <MuiThemeProvider theme={applyTheme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <IntlProvider locale={localeData.locale} messages={localeData.messages}>
                    <RTL>
                      <div className="app-main">
                        <Auth0Provider
                            domain="redcrane.jp.auth0.com"
                            clientId="epdjb4zPQrVadpNXl4QrbNtWzjCyySHA"
                            authorizationParams={{
                              display: "popup",
                              redirect_uri: window.location.origin,
                              audience: "https://api.renraccoon.com",
                              scope: "profile email read:all write:all",
                              ui_locales: locale.locale
                            }}
                        >
                          <ConnectedRouter history={history} context={ReactReduxContext}>
                            <Suspense fallback={ <div className="page-loader">
                              <CircularProgress/>
                            </div>}>
                              <AppRouter/>
                            </Suspense>
                          </ConnectedRouter>
                        </Auth0Provider>
                      </div>
                    </RTL>
                  </IntlProvider>
                </MuiPickersUtilsProvider>
              </MuiThemeProvider>
          );
        }}
      </CacheBuster>
  )

}

const mapStateToProps = (store) => {
  const { settings, auth, school } = store;
  const { sideNavColor, locale, isDirectionRTL } = settings;
  const { initURL } = auth;
  const { schoolSettings } = school;
  return { sideNavColor, locale, isDirectionRTL, auth, initURL, schoolSettings };
};

export default connect(
  mapStateToProps,
  {
    switchLanguage,
    auth0LoginSuccess,
  }
)(App);