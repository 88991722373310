import { all, call, fork, put, takeEvery, take, cancel } from "redux-saga/effects";
import { eventChannel } from 'redux-saga'
//import {roleAccess} from 'config/auth';
import { rsf } from "config/firebase";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";

import {
  ANNOUNCEMENT_SEND_ANNOUNCEMENT,
  ANNOUNCEMENT_SEND_ANNOUNCEMENT_DRAFT,
  ANNOUNCEMENT_DRAFT_ANNOUNCEMENT,
  /*
  ANNOUNCEMENT_DRAFT_ANNOUNCEMENT,
  ANNOUNCEMENT_DRAFT_ANNOUNCEMENT_SUCCESS,
  ANNOUNCEMENT_DRAFT_ANNOUNCEMENT_FAILURE,


  ANNOUNCEMENT_UPDATE_ANNOUNCEMENT_SUCCESS,
  ANNOUNCEMENT_UPDATE_ANNOUNCEMENT_FAILURE,


  ANNOUNCEMENT_DELETE_ANNOUNCEMENT_SENT_SUCCESS,
  ANNOUNCEMENT_DELETE_ANNOUNCEMENT_SENT_FAILURE,
  */
  ANNOUNCEMENT_UPDATE_ANNOUNCEMENT,
  ANNOUNCEMENT_UPDATE_ANNOUNCEMENT_DRAFT,
  ANNOUNCEMENT_DELETE_ANNOUNCEMENT_SENT,
  ANNOUNCEMENT_DELETE_ANNOUNCEMENT_DRAFT,
  // ANNOUNCEMENT_FETCH_ANNOUNCEMENT,
  // ANNOUNCEMENT_FETCH_ANNOUNCEMENT_SUCCESS,
  // ANNOUNCEMENT_FETCH_ANNOUNCEMENT_FAILURE,
  ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_SENT,
  // ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_SENT_SUCCESS,
  // ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_SENT_FAILURE,
  ANNOUNCEMENT_UNSYNC_ANNOUNCEMENT_LIST_SENT,
  ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_DRAFT,
  // ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_DRAFT_SUCCESS,
  // ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_DRAFT_FAILURE,
  ANNOUNCEMENT_UNSYNC_ANNOUNCEMENT_LIST_DRAFT,
  ANNOUNCEMENT_UNSYNC_ANNOUNCEMENT_LIST_RECENT
} from "redux/constants/ActionTypes";

import {
  announcementSyncAnnouncementListSentSuccess,
  announcementSyncAnnouncementListSentFailure,
  announcementSyncAnnouncementListDraftSuccess,
  announcementSyncAnnouncementListDraftFailure,
  announcementDeleteAnnouncementSentSuccess,
  announcementDeleteAnnouncementSentFailure,
  announcementDeleteAnnouncementDraftSuccess,
  announcementDeleteAnnouncementDraftFailure,
  announcementUpdateAnnouncementSuccess,
  announcementUpdateAnnouncementFailure,
  announcementUpdateAnnouncementDraftSuccess,
  announcementUpdateAnnouncementDraftFailure,
  announcementSendAnnouncementSuccess,
  announcementSendAnnouncementFailure,
  announcementSendAnnouncementDraftSuccess,
  announcementSendAnnouncementDraftFailure,
  announcementDraftAnnouncementSuccess,
  announcementDraftAnnouncementFailure
} from "redux/actions/Announcement";

import { showAlert } from "redux/actions/Setting";
import {announcementSyncAnnouncementListRecentSuccess, announcementSyncAnnouncementListRecentFailure} from "../actions";
import {ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_RECENT} from "../constants/ActionTypes";


// HELPERS


// FUNCTIONS
function* deleteAnnouncementSent({ payload }) {
  const { schoolId, announcementId } = payload;

  try {
    const item = yield call(rsf.database.delete, `announcements/${schoolId}/announcements/${announcementId}`);

    yield put(
      announcementDeleteAnnouncementSentSuccess({
        item
      })
    );

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.announcement_delete_success"
      })
    );
  } catch (error) {
    yield put(announcementDeleteAnnouncementSentFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* deleteAnnouncementDraft({ payload }) {
  const { schoolId, announcementId } = payload;

  try {
    const item = yield call(rsf.database.delete, `announcements/${schoolId}/drafts/${announcementId}`);

    yield put(
      announcementDeleteAnnouncementDraftSuccess({
        item
      })
    );

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.announcement_delete_success"
      })
    );
  } catch (error) {
    yield put(announcementDeleteAnnouncementDraftFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* createAnnouncement({ payload }) {
  const { schoolId } = payload;
  let updates = {};

  let createPayload = {
    id: null,
    data: {
      id: null,
      type: payload.type,
      createdAt: payload.createdAt,
      createdBy: payload.createdBy,
      updatedBy: payload.updatedBy,
      updatedAt: payload.updatedAt,
      descriptionCleanFurigana: payload.descriptionHtmlFurigana.replace(/<\/?[^>]+(>|$)/g, ""), // clean text
      descriptionHtmlFurigana: payload.descriptionHtmlFurigana,
      descriptionCleanRomaji: payload.descriptionHtmlRomaji.replace(/<\/?[^>]+(>|$)/g, ""), // clean text
      descriptionHtmlRomaji: payload.descriptionHtmlRomaji,
      titleRomaji: payload.titleRomaji,
      titleFurigana: payload.titleFurigana,
      sentAt: payload.sentAt,
      hasBeenSent: true,
      hasAttachment: payload.hasAttachment,
      attachmentUrl: payload.attachmentUrl || "",
      attachmentFilename: payload.attachmentFilename || ""
      //attachments : {},
    },
    metadata: {
      id: null,
      type: payload.type,
      sentTo: payload.sendTo,
      students: payload.students,
      studentsCount: Object.keys(payload.students).length,
      classes: payload.classes,
      hasBeenSent: true,
      sentToCount: Object.keys(payload.sendTo).length,
      unreadCount: Object.keys(payload.sendTo).length
    }
    //sentAt,
    //sentToCount,
    //unreadCount,
    //url,
  };

  const sentToPayload = {
    id: null,
    titleRomaji: payload.titleRomaji,
    titleFurigana: payload.titleFurigana,
    read: false,
    sentAt: payload.sentAt,
    type: payload.type
  };

  try {
    const announcementId = yield call(rsf.database.create, `announcements/${schoolId}/announcements/`, {});
    createPayload["id"] = announcementId;
    createPayload.data["id"] = announcementId;
    createPayload.metadata["id"] = announcementId;

    sentToPayload["id"] = announcementId;

    // prep. sentTo update.

    if (payload.attachmentFile) {
      const attachmentFile = payload.attachmentFile;

      let fileName = attachmentFile.path
        .substring(attachmentFile.path.lastIndexOf("\\") + 1)
        .split(" ")
        .join("_");
      const path = `schools/${schoolId}/announcementData/${announcementId}.${fileName.split(".")[1]}`;

      const uploadedFile = rsf.storage.uploadFile(path, attachmentFile.file);

      /*
      uploadedFile.on("state_changed", snapshot => {
        // const pct = (snapshot.bytesTransferred * 100) / snapshot.totalBytes;
      });
      */

      const channel = eventChannel(emit => uploadedFile.on('state_changed', emit));

      const con = (res => {

        console.log('res', res)
      });

      yield takeEvery(channel, con);

      // Wait for upload to complete
      yield uploadedFile;
      console.log('file uploaded')

      // Get URL for uploaded file
      const newUrl = yield call(rsf.storage.getDownloadURL, path);
      createPayload.data["attachmentUrl"] = newUrl;

      if (!newUrl){

        //yield put(announcementSendAnnouncementFailure({ error }));
        yield put(
          showAlert({
            alertType: "error",
            alertMessage: "alertMessage.common_error"
          })
        );
      }else{
        console.log('newUrl', newUrl)
      }
      console.log('createPayload.data', createPayload.data)

    }



    const item = yield call(rsf.database.patch, `announcements/${schoolId}/announcements/${announcementId}/`, { ...createPayload });

    if (payload.sendTo) {
      Object.keys(payload.sendTo).forEach(function(sendToUserId) {
        updates[`announcements/${schoolId}/sentTo/${sendToUserId}/${announcementId}`] = sentToPayload;
      });
      yield call(rsf.database.patch, "/", { ...updates });
    }

    yield put(announcementSendAnnouncementSuccess({ item }));

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.announcement_sent_success"
      })
    );
  } catch (error) {
    console.log(error, "error");
    yield put(announcementSendAnnouncementFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* draftAnnouncement({ payload }) {
  const { schoolId } = payload;

  let draftPayload = {
    id: null,
    data: {
      id: null,
      type: payload.type,
      createdAt: payload.createdAt,
      createdBy: payload.createdBy,
      updatedBy: payload.updatedBy,
      updatedAt: payload.updatedAt,
      descriptionCleanFurigana: payload.descriptionHtmlFurigana.replace(/<\/?[^>]+(>|$)/g, ""), // clean text
      descriptionHtmlFurigana: payload.descriptionHtmlFurigana,
      descriptionCleanRomaji: payload.descriptionHtmlRomaji.replace(/<\/?[^>]+(>|$)/g, ""), // clean text
      descriptionHtmlRomaji: payload.descriptionHtmlRomaji,
      titleRomaji: payload.titleRomaji,
      titleFurigana: payload.titleFurigana,
      // sentAt: payload.sentAt, // as this is draft
      hasBeenSent: false, // as this is draft
      hasAttachment: payload.hasAttachment,
      attachmentUrl: payload.attachmentUrl || "",
      attachmentFilename: payload.attachmentFilename || ""
      //attachments : {},
    },
    metadata: {
      id: null,
      type: payload.type,
      sentTo: payload.sendTo,
      classes: payload.classes,
      sentToCount: Object.keys(payload.sendTo).length,
      unreadCount: Object.keys(payload.sendTo).length
    }
    //sentAt,
    //sentToCount,
    //unreadCount,
    //url,
  };

  try {
    const announcementId = yield call(rsf.database.create, `announcements/${schoolId}/drafts/`, {});
    draftPayload["id"] = announcementId;
    draftPayload.data["id"] = announcementId;
    draftPayload.metadata["id"] = announcementId;


    if (payload.attachmentFile) {
      const attachmentFile = payload.attachmentFile;

      let fileName = attachmentFile.path
        .substring(attachmentFile.path.lastIndexOf("\\") + 1)
        .split(" ")
        .join("_");
      const path = `schools/${schoolId}/announcementData/${announcementId}.${fileName.split(".")[1]}`;

      const uploadedFile = rsf.storage.uploadFile(path, attachmentFile.file);

      /*
      uploadedFile.on("state_changed", snapshot => {
        // const pct = (snapshot.bytesTransferred * 100) / snapshot.totalBytes;
      });
      */

      const channel = eventChannel(emit => uploadedFile.on('state_changed', emit));

      const con = (res => {

        console.log('res', res)
      });

      yield takeEvery(channel, con);

      // Wait for upload to complete
      yield uploadedFile;
      console.log('file uploaded')

      // Get URL for uploaded file
      const newUrl = yield call(rsf.storage.getDownloadURL, path);
      draftPayload.data["attachmentUrl"] = newUrl;

      if (!newUrl){

        //yield put(announcementSendAnnouncementFailure({ error }));
        yield put(
          showAlert({
            alertType: "error",
            alertMessage: "alertMessage.common_error"
          })
        );
      }else{
        console.log('newUrl', newUrl)
      }
      console.log('createPayload.data', draftPayload.data)

    }

    const item = yield call(rsf.database.patch, `announcements/${schoolId}/drafts/${announcementId}/`, { ...draftPayload });

    yield put(announcementDraftAnnouncementSuccess({ item }));

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.announcement_draft_success"
      })
    );
  } catch (error) {
    yield put(announcementDraftAnnouncementFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* updateAnnouncement({ payload }) {
  const { schoolId, announcementId } = payload;

  const cleanTextFurigana = payload.descriptionHtmlFurigana.replace(/<\/?[^>]+(>|$)/g, "");
  const cleanTextRomaji = payload.descriptionHtmlRomaji.replace(/<\/?[^>]+(>|$)/g, "");


  if (payload.attachmentFile) {
    const attachmentFile = payload.attachmentFile;

    let fileName = attachmentFile.path
      .substring(attachmentFile.path.lastIndexOf("\\") + 1)
      .split(" ")
      .join("_");
    const path = `schools/${schoolId}/announcementData/${announcementId}.${fileName.split(".")[1]}`;

    const uploadedFile = rsf.storage.uploadFile(path, attachmentFile.file);

    /*
    uploadedFile.on("state_changed", snapshot => {
      // const pct = (snapshot.bytesTransferred * 100) / snapshot.totalBytes;
    });
    */

    const channel = eventChannel(emit => uploadedFile.on('state_changed', emit));

    const con = (res => {

      console.log('res', res)
    });

    yield takeEvery(channel, con);

    // Wait for upload to complete
    const uploadProcess = yield uploadedFile;
    console.log('file uploaded', uploadProcess)

    // Get URL for uploaded file
    const newUrl = yield call(rsf.storage.getDownloadURL, path);
    payload.attachmentUrl = newUrl;

    if (!newUrl){

      //yield put(announcementSendAnnouncementFailure({ error }));
      yield put(
        showAlert({
          alertType: "error",
          alertMessage: "alertMessage.common_error"
        })
      );
    }else{
      console.log('newUrl', newUrl)
    }


  }


  const updatePayload = {
    //hasAttachment: {},
    //attachments : {},
    //createdAt: {},
    //createdBy: {},

    descriptionCleanFurigana: cleanTextFurigana,
    descriptionHtmlFurigana: payload.descriptionHtmlFurigana,
    descriptionCleanRomaji: cleanTextRomaji,
    descriptionHtmlRomaji: payload.descriptionHtmlRomaji,
    //id,
    //sentAt,
    //sentToCount,
    titleRomaji: payload.titleRomaji,
    titleFurigana: payload.titleFurigana,
    updatedBy: payload.updatedBy,
    updatedAt: payload.updatedAt,
    hasAttachment: payload.hasAttachment,
    attachmentUrl: payload.attachmentUrl || "",
    attachmentFilename: payload.attachmentFilename || ""
    //type,
    //unreadCount,
    //url,
  };

  try {

    const item = yield call(rsf.database.patch, `announcements/${schoolId}/announcements/${announcementId}/data`, { ...updatePayload });

    yield put(announcementUpdateAnnouncementSuccess({ item }));

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.announcement_update_success"
      })
    );
  } catch (error) {
    yield put(announcementUpdateAnnouncementFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* updateAnnouncementDraft({ payload }) {
  const { schoolId, announcementId } = payload;

  console.log('updateAnnouncementDraf - payload', payload)

  if (payload.attachmentFile) {
    const attachmentFile = payload.attachmentFile;

    let fileName = attachmentFile.path
      .substring(attachmentFile.path.lastIndexOf("\\") + 1)
      .split(" ")
      .join("_");
    const path = `schools/${schoolId}/announcementData/${announcementId}.${fileName.split(".")[1]}`;

    const uploadedFile = rsf.storage.uploadFile(path, attachmentFile.file);

    uploadedFile.on("state_changed", snapshot => {
      // const pct = (snapshot.bytesTransferred * 100) / snapshot.totalBytes;
    });

    // Wait for upload to complete
    yield uploadedFile;

    // Get URL for uploaded file
    payload.attachmentUrl = yield call(rsf.storage.getDownloadURL, path);
  }

  let updatedDraftPayload = {
    id: announcementId,
    data: {
      id: announcementId,
      type: payload.type,
      createdAt: payload.createdAt,
      createdBy: payload.createdBy,
      updatedBy: payload.updatedBy,
      updatedAt: payload.updatedAt,
      descriptionCleanFurigana: payload.descriptionHtmlFurigana.replace(/<\/?[^>]+(>|$)/g, ""), // clean text
      descriptionHtmlFurigana: payload.descriptionHtmlFurigana,
      descriptionCleanRomaji: payload.descriptionHtmlRomaji.replace(/<\/?[^>]+(>|$)/g, ""), // clean text
      descriptionHtmlRomaji: payload.descriptionHtmlRomaji,
      titleRomaji: payload.titleRomaji,
      titleFurigana: payload.titleFurigana,
      // sentAt: payload.sentAt, // as this is draft
      hasBeenSent: false, // as this is draft
      hasAttachment: payload.hasAttachment,
      attachmentUrl: payload.attachmentUrl || "",
      attachmentFilename: payload.attachmentFilename || ""
      //attachments : {},
    },
    metadata: {
      id: announcementId,
      type: payload.type,
      sentTo: payload.sendTo,
      classes: payload.classes,
      sentToCount: Object.keys(payload.sendTo).length,
      unreadCount: Object.keys(payload.sendTo).length
    }
    //sentAt,
    //sentToCount,
    //unreadCount,
    //url,
  };

  console.log('updateAnnouncementDraf - updatedDraftPayload', updatedDraftPayload)

  try {
    const item = yield call(rsf.database.patch, `announcements/${schoolId}/drafts/${announcementId}/`, { ...updatedDraftPayload });

    yield put(announcementUpdateAnnouncementDraftSuccess({ item }));

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.announcement_update_success"
      })
    );
  } catch (error) {
    yield put(announcementUpdateAnnouncementDraftFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* createAnnouncementFromDraft({ payload }) {
  const { schoolId, draftAnnouncementId } = payload;
  let updates = {};

console.log('createAnnouncementFromDraft - payload', payload)

  let createPayload = {
    id: draftAnnouncementId,
    data: {
      id: draftAnnouncementId,
      type: payload.type,
      createdAt: payload.createdAt,
      createdBy: payload.createdBy,
      updatedBy: payload.updatedBy,
      updatedAt: payload.updatedAt,
      descriptionCleanFurigana: payload.descriptionHtmlFurigana.replace(/<\/?[^>]+(>|$)/g, ""), // clean text
      descriptionHtmlFurigana: payload.descriptionHtmlFurigana,
      descriptionCleanRomaji: payload.descriptionHtmlRomaji.replace(/<\/?[^>]+(>|$)/g, ""), // clean text
      descriptionHtmlRomaji: payload.descriptionHtmlRomaji,
      titleRomaji: payload.titleRomaji,
      titleFurigana: payload.titleFurigana,
      sentAt: payload.sentAt,
      hasBeenSent: true,
      hasAttachment: payload.hasAttachment,
      attachmentUrl: payload.attachmentUrl || "",
      attachmentFilename: payload.attachmentFilename || ""
      //attachments : {},
    },
    metadata: {
      id: draftAnnouncementId,
      type: payload.type,
      sentTo: payload.sendTo,
      students: payload.students,
      studentsCount: Object.keys(payload.students).length,
      classes: payload.classes,
      hasBeenSent: true,
      sentToCount: Object.keys(payload.sendTo).length,
      unreadCount: Object.keys(payload.sendTo).length
    }
    //sentAt,
    //sentToCount,
    //unreadCount,
    //url,
  };

  const sentToPayload = {
    id: draftAnnouncementId,
    titleRomaji: payload.titleRomaji,
    titleFurigana: payload.titleFurigana,
    read: false,
    sentAt: payload.sentAt,
    type: payload.type
  };

  try {


    if (payload.attachmentFile) {
      const attachmentFile = payload.attachmentFile;

      let fileName = attachmentFile.path
        .substring(attachmentFile.path.lastIndexOf("\\") + 1)
        .split(" ")
        .join("_");
      const path = `schools/${schoolId}/announcementData/${draftAnnouncementId}.${fileName.split(".")[1]}`;

      const uploadedFile = rsf.storage.uploadFile(path, attachmentFile.file);

      uploadedFile.on("state_changed", snapshot => {
        // const pct = (snapshot.bytesTransferred * 100) / snapshot.totalBytes;
      });

      // Wait for upload to complete
      yield uploadedFile;

      // Get URL for uploaded file
      createPayload.data["attachmentUrl"] = yield call(rsf.storage.getDownloadURL, path);
    }

    const item = yield call(rsf.database.update, `announcements/${schoolId}/announcements/${draftAnnouncementId}/`, { ...createPayload });

    if (payload.sendTo) {
      Object.keys(payload.sendTo).forEach(function(sendToUserId) {
        updates[`announcements/${schoolId}/sentTo/${sendToUserId}/${draftAnnouncementId}`] = sentToPayload;
      });
      yield call(rsf.database.patch, "/", { ...updates });
    }

    // Delete draft after sending announcement
    yield call(rsf.database.delete, `announcements/${schoolId}/drafts/${draftAnnouncementId}`);

    yield put(announcementSendAnnouncementDraftSuccess({ item }));

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.announcement_sent_success"
      })
    );
  } catch (error) {
    console.log(error, "error");
    yield put(announcementSendAnnouncementDraftFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

// SYNCS
function* syncAnnouncementListRecent({ payload }) {
  const { schoolId } = payload;
  const sync = yield fork(
      rsf.database.sync,
      firebase.database().ref(`announcements/${schoolId}/announcements`).orderByChild('sentAt').limitToLast(30),
      {
        successActionCreator: announcementSyncAnnouncementListRecentSuccess,
        failureActionCreator: announcementSyncAnnouncementListRecentFailure
      }
  );
  // On unsync request, cancel sync.
  yield take(ANNOUNCEMENT_UNSYNC_ANNOUNCEMENT_LIST_RECENT);
  yield cancel(sync);
}

function* syncAnnouncementListSent({ payload }) {
  const { schoolId } = payload;
  const sync = yield fork(
    rsf.database.sync,
    /*
    firebase
      .database()
      .ref(`announcements/${schoolId}/announcements`)
      .orderByChild("sentAt"),*/
    firebase.database().ref(`announcements/${schoolId}/announcements`).orderByChild('sentAt'),
    {
      successActionCreator: announcementSyncAnnouncementListSentSuccess,
      failureActionCreator: announcementSyncAnnouncementListSentFailure
    }
  );
  // On unsync request, cancel sync.
  yield take(ANNOUNCEMENT_UNSYNC_ANNOUNCEMENT_LIST_SENT);
  yield cancel(sync);
}

function* syncAnnouncementListDraft({ payload }) {
  const { schoolId } = payload;
  const sync = yield fork(rsf.database.sync, firebase.database().ref(`announcements/${schoolId}/drafts`), {
    successActionCreator: announcementSyncAnnouncementListDraftSuccess,
    failureActionCreator: announcementSyncAnnouncementListDraftFailure
  });
  // On unsync request, cancel sync.
  yield take(ANNOUNCEMENT_UNSYNC_ANNOUNCEMENT_LIST_DRAFT);
  yield cancel(sync);
}

// WATCHERS

export default function* rootSaga() {
  yield all([
    takeEvery(ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_RECENT, syncAnnouncementListRecent),
      takeEvery(ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_SENT, syncAnnouncementListSent),
    takeEvery(ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_DRAFT, syncAnnouncementListDraft),
    takeEvery(ANNOUNCEMENT_DELETE_ANNOUNCEMENT_SENT, deleteAnnouncementSent),
    takeEvery(ANNOUNCEMENT_DELETE_ANNOUNCEMENT_DRAFT, deleteAnnouncementDraft),
    takeEvery(ANNOUNCEMENT_UPDATE_ANNOUNCEMENT, updateAnnouncement),
    takeEvery(ANNOUNCEMENT_UPDATE_ANNOUNCEMENT_DRAFT, updateAnnouncementDraft),
    takeEvery(ANNOUNCEMENT_SEND_ANNOUNCEMENT, createAnnouncement),
    takeEvery(ANNOUNCEMENT_SEND_ANNOUNCEMENT_DRAFT, createAnnouncementFromDraft),
    takeEvery(ANNOUNCEMENT_DRAFT_ANNOUNCEMENT, draftAnnouncement)
  ]);
}
