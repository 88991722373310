import {
  /*
  ANNOUNCEMENT_SEND_ANNOUNCEMENT,
  ANNOUNCEMENT_SEND_ANNOUNCEMENT_SUCCESS,
  ANNOUNCEMENT_SEND_ANNOUNCEMENT_FAILURE,

  ANNOUNCEMENT_DRAFT_ANNOUNCEMENT,
  ANNOUNCEMENT_DRAFT_ANNOUNCEMENT_SUCCESS,
  ANNOUNCEMENT_DRAFT_ANNOUNCEMENT_FAILURE,

  ANNOUNCEMENT_UPDATE_ANNOUNCEMENT,
  ANNOUNCEMENT_UPDATE_ANNOUNCEMENT_SUCCESS,
  ANNOUNCEMENT_UPDATE_ANNOUNCEMENT_FAILURE,

  ANNOUNCEMENT_DELETE_ANNOUNCEMENT_SENT,
  ANNOUNCEMENT_DELETE_ANNOUNCEMENT_SENT_SUCCESS,
  ANNOUNCEMENT_DELETE_ANNOUNCEMENT_SENT_FAILURE,

  ANNOUNCEMENT_DELETE_ANNOUNCEMENT_DRAFT,
  ANNOUNCEMENT_DELETE_ANNOUNCEMENT_DRAFT_SUCCESS,
  ANNOUNCEMENT_DELETE_ANNOUNCEMENT_DRAFT_FAILURE,
  */

  ANNOUNCEMENT_FETCH_ANNOUNCEMENT,
  ANNOUNCEMENT_FETCH_ANNOUNCEMENT_SUCCESS,
  ANNOUNCEMENT_FETCH_ANNOUNCEMENT_FAILURE,
  ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_RECENT,
  ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_RECENT_SUCCESS,
  ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_RECENT_FAILURE,
  ANNOUNCEMENT_UNSYNC_ANNOUNCEMENT_LIST_RECENT,
  ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_SENT,
  ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_SENT_SUCCESS,
  ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_SENT_FAILURE,
  ANNOUNCEMENT_UNSYNC_ANNOUNCEMENT_LIST_SENT,
  ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_DRAFT,
  ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_DRAFT_SUCCESS,
  ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_DRAFT_FAILURE,
  ANNOUNCEMENT_UNSYNC_ANNOUNCEMENT_LIST_DRAFT
} from "redux/constants/ActionTypes";

const initialSettings = {
  announcementListRecent: {},
  announcementListSent: {},
  announcementListDraft: {},
  announcementListRecentSyncing: false,
  announcementListSentSyncing: false,
  announcementListDraftSyncing: false,
  announcementListRecentSynced: false,
  announcementListSentSynced: false,
  announcementListDraftSynced: false,
  selectedAnnouncement: {},
  selectedAnnouncementLoading: false,
  selectedAnnouncementLoaded: false
};

const Announcement = (state = initialSettings, { type, payload }) => {


  switch (type) {
    case ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_RECENT: {
      return {
        ...state,
        announcementListRecentSyncing: true,
        announcementListRecentSynced: false
      };
    }
    case ANNOUNCEMENT_UNSYNC_ANNOUNCEMENT_LIST_RECENT: {
      return {
        ...state,
        announcementListRecentSyncing: false,
        announcementListRecentSynced: false,
        announcementListRecent: {}
      };
    }
    case ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_RECENT_SUCCESS: {
      return {
        ...state,
        announcementListRecentSyncing: false,
        announcementListRecentSynced: true,
        announcementListRecent: payload
      };
    }

    case ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_RECENT_FAILURE: {
      return {
        ...state,
        announcementListRecentSyncing: false,
        announcementListRecentSynced: false,
        announcementListRecent: {}
      };
    }
    case ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_SENT: {
      return {
        ...state,
        announcementListSentSyncing: true,
        announcementListSentSynced: false
      };
    }
    case ANNOUNCEMENT_UNSYNC_ANNOUNCEMENT_LIST_SENT: {
      return {
        ...state,
        announcementListSentSyncing: false,
        announcementListSentSynced: false,
        announcementListSent: {}
      };
    }
    case ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_SENT_SUCCESS: {
      return {
        ...state,
        announcementListSentSyncing: false,
        announcementListSentSynced: true,
        announcementListSent: payload
      };
    }

    case ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_SENT_FAILURE: {
      return {
        ...state,
        announcementListSentSyncing: false,
        announcementListSentSynced: false,
        announcementListSent: {}
      };
    }

    case ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_DRAFT: {
      return {
        ...state,
        announcementListDraftSyncing: true,
        announcementListDraftSynced: false
      };
    }
    case ANNOUNCEMENT_UNSYNC_ANNOUNCEMENT_LIST_DRAFT: {
      return {
        ...state,
        announcementListDraftSyncing: false,
        announcementListDraftSynced: false,
        announcementListDraft: {}
      };
    }
    case ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_DRAFT_SUCCESS: {
      return {
        ...state,
        announcementListDraftSyncing: false,
        announcementListDraftSynced: true,
        announcementListDraft: payload
      };
    }

    case ANNOUNCEMENT_SYNC_ANNOUNCEMENT_LIST_DRAFT_FAILURE: {
      return {
        ...state,
        announcementListDraftSyncing: false,
        announcementListDraftSynced: false,
        announcementListDraft: {}
      };
    }

    case ANNOUNCEMENT_FETCH_ANNOUNCEMENT: {
      return {
        ...state,
        selectedAnnouncementLoading: true,
        selectedAnnouncementLoaded: false
      };
    }
    case ANNOUNCEMENT_FETCH_ANNOUNCEMENT_SUCCESS: {
      return {
        ...state,
        selectedAnnouncement: payload,
        selectedAnnouncementLoading: false,
        selectedAnnouncementLoaded: true
      };
    }

    case ANNOUNCEMENT_FETCH_ANNOUNCEMENT_FAILURE: {
      return {
        ...state,
        selectedAnnouncement: {},
        selectedAnnouncementLoading: false,
        selectedAnnouncementLoaded: false
      };
    }

    default:
      return state;
  }
};

export default Announcement;
