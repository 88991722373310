import React, {useEffect, useMemo} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {useSelector, useDispatch} from "react-redux";
import {Route, Switch} from "react-router-dom";
import { routesDefault } from "config/routes";
import { auth0Login } from "redux/actions/Auth";
import CircularProgress from "../components/CircularProgress";
// import {SetupContainer} from "./SetupContainer";

const AuthenticatedContainer = React.lazy(() => import ("routes/AuthenticatedContainer"));
const Login = React.lazy(() => import ("routes/auth/login"));
const Signup = React.lazy(() => import ("routes/auth/signup"));
const ForgotPassword = React.lazy(() => import ("routes/auth/forgotPassword"));

export function AppRouter(props) {
    const dispatch = useDispatch();
    const {
        isAuthenticated,
        isLoading,
        getAccessTokenSilently,
        user,
    } = useAuth0();

    const auth = useSelector(state => state.auth);

    useEffect(() => {
        if (isAuthenticated && user) {
            const renraccoonApiToken = getAccessTokenSilently();
            renraccoonApiToken.then(token => {
                dispatch(auth0Login({
                    user,
                    token
                }))
            })
        }
    }, [user, isAuthenticated]);

    /*
    if(auth.user && !isSetupComplete){
        return(
            <SetupContainer/>
        )
    }*/
    if(auth.loading || isLoading){
        return(
            <div className="page-loader">
                <CircularProgress/>
            </div>
        )
    }

    return(
        <>
            {auth.loaded
                ? <AuthenticatedContainer/>
                : <Switch>
                    <Route exact path={routesDefault.forgotPassword} component={ForgotPassword}/>
                    <Route exact path={routesDefault.signup} component={Signup}/>
                    <Route exact path={routesDefault.login} component={Login}/>
                </Switch>
            }
        </>
    )
}

export default AppRouter;