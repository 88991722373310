
import * as firebase from 'firebase/app';
import "firebase/auth";
import "firebase/storage";
import "firebase/database";

const auth = firebase.auth();
const database = firebase.database();
const storage = firebase.storage();

export const signInCustomTokenRequest = async (token) =>
    await auth.signInWithCustomToken(token)
        .then(authUser => authUser)
        .catch(error => {
            return {error: {message: error.message, code: error.code}}
        });

const resetBadgeCounterRequest = async (sid, badgeType) =>
  await database.ref(`schoolMetadata/${sid}/badges/${badgeType}`)
    .set(0)
    .then(res => res)
    .catch(error => error);

const getUserClaims = async () =>
  await auth.currentUser.getIdTokenResult()
    .then((idTokenResult) => {
      return idTokenResult.claims;
    })
    .catch(error => error);


const getUserIdToken = async () =>
  await auth.currentUser.getIdToken()
    .then((result) => {
      return result;
    })
    .catch(error => error);


const reAuthenticateUserRequest = async credentials =>
  await auth.currentUser
    .reauthenticateWithCredential(credentials)
    .then(authUser => authUser)
    .catch(error => error);


const authRequestPasswordResetRequest = async email =>
  await auth.sendPasswordResetEmail(email, auth.actionCodeSettings || {})
    .then(res => {
      return res;
    })
    .catch(error => error);



const readDBRequest = async (path) =>
  await database.ref(path)
    .once("value")
    .then(res => res.val())
    .catch(error => error);


const setDBRequest = async (path, value) =>
  await database.ref(path)
    .set(value)
    .then(res => res)
    .catch(error => error);

const updateDBWithRefRequest = async (path, value) =>
  await database.ref(path)
    .update(value)
    .then(res => res)
    .catch(error => error);

const updateDBWithArrayRequest = async (updates) =>
  await database.ref()
    .update(updates)
    .then(res => {
      console.log('res1', res)
      return res;
    })
    .catch(error => {
      console.log('error', error)
      return error;
    });

const deleteDBRequest = async (path) =>
  await database.ref(path)
    .remove()
    .then(res => res)
    .catch(error => error);

const uploadImageWithMetadataRequest = async (path, file, metadata) =>
  await storage.ref()
    .child(path)
    .put(file, metadata)
    .then(res => res)
    .catch(error => error);

const uploadImageBase64WithMetadataRequest = async (path, base64, metadata) =>
  await storage.ref()
    .child(path)
    .putString(base64, 'base64', metadata)
    .then(snapshot => {
        return snapshot.ref.getDownloadURL()
    })
    .then(downloadUrl => downloadUrl)
    .catch(error => error);

const SignoutUserRequest = async () =>
  await auth.signOut()
    .then(authUser => authUser)
    .catch(error => {
      return {error: {message: error, code: error.code}}
    });

const SigninUserWithPasswordRequest = async ({email, password}) =>
  await auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
    .then(() => auth.signInWithEmailAndPassword(email, password))
    .then(authUser => authUser)

const ChangeUserPasswordRequest = async newPassword =>
  await auth.currentUser
    .updatePassword(newPassword)
    .then(authUser => authUser)
    .catch(error => {
      return {error: {message: error, code: error.code}}
    });

const UpdateUserProfileRequest = async payload =>
  await auth.currentUser
    .updateProfile(payload)
    .then(authUser => authUser)
    .catch(error => {
      return {error: {message: error, code: error.code}}
    });

export {
  getUserClaims,
  getUserIdToken,
  reAuthenticateUserRequest,
  authRequestPasswordResetRequest,
  readDBRequest,
  setDBRequest,
  updateDBWithRefRequest,
  updateDBWithArrayRequest,
  deleteDBRequest,
  uploadImageWithMetadataRequest,
  uploadImageBase64WithMetadataRequest,
  SignoutUserRequest,
  SigninUserWithPasswordRequest,
  ChangeUserPasswordRequest,
  UpdateUserProfileRequest,
  resetBadgeCounterRequest
};
