import {
    COLLAPSED_DRAWER,
    INSIDE_THE_HEADER,
    VERTICAL_NAVIGATION,
} from 'redux/constants/ActionTypes';
import {
    DARK_GREEN,
} from 'redux/constants/ThemeColors';
import {languageFallback, languageSwitcherList} from './language';
import packageData from "../../package.json";

function timestampNow() {
    const now = new Date();
    return now.getTime();
}

const rltLocale = ['ar'];
const initialSettings = {
    navCollapsed: true,
    drawerType: COLLAPSED_DRAWER,
    width: window.innerWidth,
    isDirectionRTL: false,
    navigationStyle: VERTICAL_NAVIGATION,
    horizontalNavPosition: INSIDE_THE_HEADER,
    locale: languageSwitcherList[languageFallback],
    tourStart: false,
    themeColor: DARK_GREEN,
    sideNavColor: '',
    darkTheme: false,
    timeStampNow: timestampNow(),
    showAlert: false,
    alertType: '',
    alertMessage: '',
    alertMessageExtra: ''
};

const colorSettings = {
    defaultColor: 'dark-green',
    managerColor: 'dark-deep-purple',
    teacherColor: 'dark-blue',
    parentColor: 'dark-orange',
};

const version = {
    date: packageData.description,
    version: packageData.version
}

export {
    version,
    initialSettings,
    rltLocale,
    colorSettings
};