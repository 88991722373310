import { all, call, fork, put, takeEvery, take, cancel } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { roleAccess } from "config/auth";
import { rsf } from "config/firebase";
import { push } from "connected-react-router";
import firebase from "firebase/app";
import "firebase/auth";
import {signInCustomTokenRequest, SigninUserWithPasswordRequest} from "../../util/firebaseHelpers";
import {getUserIdToken, getUserClaims} from "../../util/firebaseHelpers";
import {apiCall} from "../../util/apiHelpers";

import {
  AUTH_LOGOUT,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGIN,
  AUTH_LOGIN_SUCCESS,
  AUTH_FETCH_USER_PROFILE,
  AUTH_UPDATE_USER_PROFILE,
  AUTH_FETCH_USER_SETTINGS,
  AUTH_UPDATE_USER_SETTINGS,
  AUTH_CHANGE_PASSWORD,
  AUTH_REQUEST_USER_ID_TOKEN,
  AUTH_CHANGE_USER_LANGUAGE,
  AUTH_REAUTH_USER,
  AUTH_REQUEST_PASSWORD_RESET,
  AUTH_SIGNUP,
    AUTH0_LOGIN
} from "redux/constants/ActionTypes";

import {
  showAuthMessage,
  loginSuccess,
  logoutSuccess,
  loginFailure,
  authFetchUserProfileSuccess,
  authFetchUserProfileFailure,
  authUpdateUserProfileSuccess,
  authUpdateUserProfileFailure,
  authFetchUserSettingsSuccess,
  authFetchUserSettingsFailure,
  authUpdateUserSettingsSuccess,
  authUpdateUserSettingsFailure,
  authSyncUserMetadataFailure,
  authSyncUserMetadataSuccess,
  authChangeUserLanguageSuccess,
  authChangeUserLanguageFailure,
  authReAuthUserSuccess,
  authReAuthUserFailure,
  authRequestPasswordResetSuccess,
  authRequestPasswordResetFailure,
  authSignupSuccess,
  authSignupFailure,
  auth0LoginSuccess
} from "redux/actions/Auth";

import {
  //setLocale,
  showAlert
} from "../actions";
import {userloginWithEmailPassword} from "../actions";

const auth = rsf.app.auth();

const reAuthenticateUserRequest = async credentials =>
  await auth.currentUser
    .reauthenticateAndRetrieveDataWithCredential(credentials)
    .then(authUser => authUser)
    .catch(error => error);

const authRequestPasswordResetRequest = async email =>
  await auth
    .sendPasswordResetEmail(email, auth.actionCodeSettings || {})
    .then(res => {
      return res;
    })
    .catch(error => error);

function* fetchUserProfile({ payload }) {
  const { uid } = payload;
  if (uid) {
    try {
      const userProfile = yield call(rsf.database.read, "userProfile/" + uid);

      if (userProfile.message) {
        yield put(showAuthMessage(userProfile.message));
        yield put(authFetchUserProfileFailure());
      } else {
        yield put(authFetchUserProfileSuccess(userProfile));
      }
    } catch (error) {
      console.log("ERROR: SAGA - Auth - fetchUserProfile", error.code);
      yield put(authFetchUserProfileFailure());
    }
  }
}

function* updateUserProfile({ payload }) {
  let { userId, sid, userProfile, avatarFile, prevAvatar  } = payload;

  const prevAvatarId = prevAvatar.id;


  try {
    const tokenId = yield call(getUserIdToken);

    if (avatarFile) {
      const formData = new FormData();
      formData.append('avatar', avatarFile.file);
      const apiUrl = `/v1/file/${sid}/upload/avatar`;
      const fileData = yield call(apiCall, 'POST', apiUrl, tokenId, formData, true);
      if(fileData.files.length > 0){
        userProfile.avatarId = fileData.files[0].id;
        userProfile.avatarUrl = fileData.files[0].url.avatar;
      }
    }

    yield call(rsf.database.update, "userProfile/" + userId, userProfile);
    yield put(authUpdateUserProfileSuccess(userProfile));

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.profile_update_success"
      })
    );
    if(avatarFile && prevAvatarId){
      yield call(apiCall, 'DELETE', `/v1/file/${sid}/delete/${prevAvatarId}`, tokenId);
    }
  } catch (error) {
    console.log('ERROR: Saga-updateUserProfile', error)
    yield put(authUpdateUserProfileFailure(error));

    yield put(
      showAlert({
        alertType: "error",
        alertMessage: error.message
      })
    );
  }
}

function* fetchUserSettings({ payload }) {
  const { uid } = payload;

  try {
    const userSettings = yield call(rsf.database.read, "userSettings/" + uid);
    if (userSettings.message) {
      yield put(showAuthMessage(userSettings.message));
      yield put(authFetchUserSettingsFailure(userSettings));
    } else {
      yield put(authFetchUserSettingsSuccess(userSettings));
    }
  } catch (error) {
    console.log("ERROR: SAGA - Auth - fetchUserSettings", error.code);
  }
}

function* updateUserSettings({ payload }) {
  let { itemValue, all } = payload;
  if (all !== undefined) {
    if (all) {
      itemValue = {
        enableAll: true,
        enableOnNewAnnouncement: true,
        enableOnNewAttendance: true,
        enableOnNewCalendarEvent: true,
        enableOnNewMessage: true,
        enableOnNewNotifications: true
      };
    } else {
      itemValue = {
        enableAll: false,
        enableOnNewAnnouncement: false,
        enableOnNewAttendance: false,
        enableOnNewCalendarEvent: false,
        enableOnNewMessage: false,
        enableOnNewNotifications: false
      };
    }
  }

  try {
    yield call(rsf.database.update, `userSettings/${payload.uid}/${payload.itemField}`, itemValue);
    const userSettings = yield call(rsf.database.read, "userSettings/" + payload.uid);
    yield put(authUpdateUserSettingsSuccess(userSettings));
  } catch (error) {
    console.log("ERROR: SAGA - Auth - fetchUserSettings", error.code);
    yield put(authUpdateUserSettingsFailure(error));
  }
}

function* changeUserLanguage({ payload }) {
  const { userId, locale } = payload;

  try {
    yield call(rsf.database.update, `userSettings/${userId}/locale`, locale);
    yield call(rsf.database.update, `userMetadata/${userId}/locale`, locale);

    yield put(authChangeUserLanguageSuccess());

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.change_user_language_success"
      })
    );
  } catch (error) {
    yield put(authChangeUserLanguageFailure(error));

    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* authReAuthenticateUser({ payload }) {
  const { email, password } = payload;

  const credentials = firebase.auth.EmailAuthProvider.credential(email, password);

  try {
    const reAuthenticatedUser = yield call(reAuthenticateUserRequest, credentials);
    if (reAuthenticatedUser.message) {
      yield put(authReAuthUserFailure(reAuthenticatedUser.message));
    } else {
      yield put(authReAuthUserSuccess());
    }
  } catch (error) {
    yield put(authReAuthUserFailure(error.message));
  }
}

function* authChangePassword({ payload }) {
  const { password } = payload;

  try {
    yield call(rsf.auth.updatePassword, password);
    yield put(authReAuthUserFailure());
    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.change_password_success"
      })
    );
  } catch (error) {
    yield put(authReAuthUserFailure(error.message));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* authRequestPasswordReset({ payload }) {
  const { email, locale } = payload;
  auth.languageCode = locale.locale;

  try {
    const response = yield call(authRequestPasswordResetRequest, email);

    if (response && response.message) {
      yield put(authRequestPasswordResetFailure(response.code));
      yield put(
        showAlert({
          alertType: "error",
          alertMessage: "alertMessage.common_error"
        })
      );
    } else {
      yield put(authRequestPasswordResetSuccess());
      yield put(
        showAlert({
          alertType: "success",
          alertMessage: "alertMessage.password_reset_success"
        })
      );
    }
  } catch (error) {
    yield put(authRequestPasswordResetFailure(error.code));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* authSignup({ payload }) {
  const { username, password } = payload;
  try {
    const tokenId = process.env.REACT_APP_SYSTEM_API_KEY;

    const apiUrl = `/v1/signup/client`;
    const result = yield call(apiCall, 'POST', apiUrl, tokenId, payload, false);
    yield put(authSignupSuccess({success: true}));
    yield put(userloginWithEmailPassword({
      email: username,
      password
    }));
  } catch (error) {
    if (error.message) {
      yield put(authSignupFailure(error.message));
    } else {
      yield put(authSignupFailure("alertMessage.common_error"));
    }
    yield put(
        showAlert({
          alertType: "error",
          alertMessage: "alertMessage.common_error"
        })
    );
  }
}

// SYNCS
function* syncUserMetadata({ payload }) {
  const { uid } = payload;

  const sync = yield fork(rsf.database.sync, `userMetadata/${uid}`, {
    successActionCreator: authSyncUserMetadataSuccess,
    failureActionCreator: authSyncUserMetadataFailure
  });

  // On unsync request, cancel sync.
  yield take(AUTH_LOGOUT_SUCCESS);
  yield cancel(sync);
}

function* loginUserWithEmailPassword({ payload }) {
  const { email, password } = payload;

  try {
    yield call(SigninUserWithPasswordRequest, {email, password});
  } catch (error) {
    let alertPayload = {
      alertType: "error",
      alertMessage: "alertMessage.common_error"
    }
    let loginFailureMessage = null;
    switch (error.code) {
      case "auth/user-not-found":
        alertPayload.alertMessage = "alertMessage.login_user_not_found";
        loginFailureMessage = "auth.login_user_not_found";
        break;

      case "auth/invalid-email":
        alertPayload.alertMessage = "alertMessage.login_invalid_email";
        loginFailureMessage = "auth.login_invalid_email";
        break;

      case "auth/wrong-password":
        alertPayload.alertMessage = "alertMessage.login_wrong_password";
        loginFailureMessage = "auth.login_wrong_password";
        break;

      default:
        alertPayload.alertMessage = `alertMessage.${error.code}`;
        loginFailureMessage = `auth.${error.code}`;
    }
    yield put(showAlert(alertPayload));
    yield put(loginFailure(loginFailureMessage));
  }
}

function* auth0Login({ payload }) {
  try {
    const signInToken = yield call(apiCall, "GET", "/v1/authorized/token", payload.token);
    yield call(signInCustomTokenRequest, signInToken.token);
    yield put(auth0LoginSuccess(payload));
  } catch (error) {
    yield put(loginFailure(error));
  }
}

function* logoutUser() {
  try {
    const signOutUser = yield call(rsf.auth.signOut);
    if (signOutUser === undefined) {
      //TODO: Browser pushToken removal from DB.
      localStorage.removeItem("user_id");
      yield put(logoutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

// WATCHERS
function* authStatusWatcher() {
  const channel = eventChannel(emit => {
    return auth.onAuthStateChanged(user => emit({ user }), error => emit({ error }));
  });

  while (true) {
    const { user } = yield take(channel);
    if (user) {
      const uid = user.uid;
      const claims = yield call(getUserClaims);
      const tokenId = yield call(getUserIdToken);

      // LoginSuccessPayload
      const payload = {
        signInProvider: claims.firebase.sign_in_provider,
        displayName: user.displayName,
        email: user.email,
        emailVerified: user.emailVerified,
        phoneNumber: user.phoneNumber,
        photoURL: user.photoURL,
        uid: uid,
        role: claims.role,
        cid: claims.cid || '',
        school: '',
        sid: undefined,
        sids: [],
        tokenId
      };

      if(!claims.sid) {
        yield call(logoutUser);
      }

      // check sid
      if (claims.sid === null || claims.sid === undefined){
        payload.school = undefined;
        payload.sid = undefined;
      } else if (typeof claims.sid === 'string' || claims.sid instanceof String){
        payload.school = claims.sid;
        payload.sid = claims.sid;
        payload.sids.push(claims.sid);
      } else if (typeof claims.sid === 'object' && !Array.isArray(claims.sid)) {
        Object.keys(claims.sid).forEach(sKey => {
          payload.sids.push(sKey);
        });
        payload.school = payload.sids[0];
        payload.sid = payload.sids[0];
      } else if (Array.isArray(claims.sid)){
        payload.school = payload.sids[0];
        payload.sid = payload.sids[0];
        payload.sids = claims.sid;
      } else {
        payload.school = undefined;
        payload.sid = undefined;
      }

      yield put(loginSuccess(payload));
      yield put(push("/dashboard"));

      // Check user role
      if (roleAccess[claims.role] === true) {
        switch (claims.role) {
          case "system":
            //yield call(logoutUser);
            break;
          case "parent" || "guardian":
            break;
          default:
        }
      } else {
        // Redirect on no user claims to unauthorized page.
        yield put(push("/unauthorized"));
        yield call(logoutUser);
      }
    } else {
      // Redirect on no user.
      //console.log("authStatusWatcher push to /");
      yield put(push("/login"));
    }
  }
}

export default function* rootSaga() {
  yield fork(authStatusWatcher);
  yield all([
    takeEvery(AUTH_LOGOUT, logoutUser),
    takeEvery(AUTH_LOGIN, loginUserWithEmailPassword),
    takeEvery(AUTH_LOGIN_SUCCESS, syncUserMetadata),
    takeEvery(AUTH_LOGIN_SUCCESS, fetchUserProfile),
    takeEvery(AUTH_LOGIN_SUCCESS, fetchUserSettings),
    takeEvery(AUTH_FETCH_USER_PROFILE, fetchUserProfile),
    takeEvery(AUTH_UPDATE_USER_PROFILE, updateUserProfile),
    takeEvery(AUTH_FETCH_USER_SETTINGS, fetchUserSettings),
    takeEvery(AUTH_UPDATE_USER_SETTINGS, updateUserSettings),
    takeEvery(AUTH_REQUEST_USER_ID_TOKEN, getUserIdToken),
    takeEvery(AUTH_CHANGE_USER_LANGUAGE, changeUserLanguage),
    takeEvery(AUTH_REAUTH_USER, authReAuthenticateUser),
    takeEvery(AUTH_CHANGE_PASSWORD, authChangePassword),
    takeEvery(AUTH_REQUEST_PASSWORD_RESET, authRequestPasswordReset),
    takeEvery(AUTH_SIGNUP, authSignup),
    takeEvery(AUTH0_LOGIN, auth0Login),
    //takeEvery(AUTH_ADD_INVITECODE, addInviteCode),
    //takeEvery(AUTH_SIGNUP_PROVIDER_PASSWORD, signupUserProviderPassword),
    //takeEvery(AUTH_SIGNUP_PROVIDER_PASSWORD_SUCCESS, sendEmailVerification),
    //takeEvery(USER_UPDATE_PUSH_NOTIFICATION_TOKEN, updateUserPushNotificationToken),
  ]);
}
