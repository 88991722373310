import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
  take,
  cancel,
    race,
} from "redux-saga/effects";
import { eventChannel } from 'redux-saga';
import { rsf } from "config/firebase";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";
import moment from "moment-timezone";
import { cloneDeep } from "lodash";

import {
  SCHOOL_FETCH_SCHOOL_PROFILE,
  SCHOOL_UPDATE_SCHOOL_PROFILE,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
  SCHOOL_ACADEMICYEAR_FETCH_SCHOOL_ACADEMICYEAR_LIST,
  SCHOOL_ACADEMICYEAR_SYNC_SCHOOL_ACADEMICYEAR_LIST,
  SCHOOL_ACADEMICYEAR_UNSYNC_SCHOOL_ACADEMICYEAR_LIST,
  SCHOOL_ACADEMICYEAR_ADD_SCHOOL_ACADEMICYEAR,
  SCHOOL_ACADEMICYEAR_UPDATE_SCHOOL_ACADEMICYEAR,
  SCHOOL_ACADEMICYEAR_DELETE_SCHOOL_ACADEMICYEAR,
  SCHOOL_ROOM_FETCH_ROOM_LIST,
  SCHOOL_ROOM_ADD_ROOM,
  SCHOOL_ROOM_UPDATE_ROOM,
  SCHOOL_ROOM_DELETE_ROOM_LIST,
  SCHOOL_ROOM_SYNC_ROOM_LIST,
  SCHOOL_ROOM_UNSYNC_ROOM_LIST,
    SCHOOL_FETCH_SCHOOL_USERS,
    SCHOOL_LISTENER_ON_SCHOOL_USERS,
    AUTH_LOGOUT,
} from "redux/constants/ActionTypes";

import {
  schoolFetchSchoolProfileSuccess,
  schoolFetchSchoolProfileFailure,
  schoolUpdateSchoolProfileSuccess,
  schoolUpdateSchoolProfileFailure,
  schoolFetchSchoolSettingsSuccess,
  schoolFetchSchoolSettingsFailure,
  schoolSyncSchoolMetadataSuccess,
  schoolSyncSchoolMetadataFailure,
  schoolAcademicYearFetchSchoolAcademicYearListSuccess,
  schoolAcademicYearFetchSchoolAcademicYearListFailure,
  schoolAcademicYearSyncSchoolAcademicYearListSuccess,
  schoolAcademicYearSyncSchoolAcademicYearListFailure,
  schoolAcademicYearAddSchoolAcademicYearSuccess,
  schoolAcademicYearAddSchoolAcademicYearFailure,
  schoolAcademicYearUpdateSchoolAcademicYearSuccess,
  schoolAcademicYearUpdateSchoolAcademicYearFailure,
  schoolAcademicYearDeleteSchoolAcademicYearSuccess,
  schoolAcademicYearDeleteSchoolAcademicYearFailure,
  schoolRoomFetchRoomListSuccess,
  schoolRoomFetchRoomListFailure,
  schoolRoomAddRoomSuccess,
  schoolRoomAddRoomFailure,
  schoolRoomUpdateRoomSuccess,
  schoolRoomUpdateRoomFailure,
  //schoolRoomDeleteRoomListSuccess,
  //schoolRoomDeleteRoomListFailure,
  schoolRoomSyncRoomListSuccess,
  schoolRoomSyncRoomListFailure,
  schoolFetchSchoolUsersSuccess,
    schoolFetchSchoolUsersFailure,
    schoolListenerEventSchoolUsers,
    schoolListenerOffSchoolUsers
} from "redux/actions/School";

import { logout } from "redux/actions/Auth";
import { showAlert } from "redux/actions/Setting";
import {getUserIdToken} from "../../util/firebaseHelpers";
import {apiCall} from "../../util/apiHelpers";

// HELPERS

function getDaysFireBaseObjectFromDaysListAndAddEventToSchoolCalendar(
  daysList,
  schoolCalendarRef,
  defaultSchoolCalendarItem
) {
  let daysObject = {};

  // schoolCalendarItem add Item
  daysList.forEach(async day => {
    if (!day.id) {
      if (day.dateStart) {
        let schoolCalendarItem = {
          ...defaultSchoolCalendarItem,
          eventType: day.eventType,
          dateStart: day.dateStart,
          dateEnd: day.dateEnd,
          multipleDays: !!(day.dateEnd - day.dateStart),
          totalDays: moment(day.dateEnd).diff(day.dateStart, "days") + 1
        };

        // push data in schoolCalendar and get key from there
        const key = schoolCalendarRef.push(schoolCalendarItem).key;
        daysObject[key] = Object.assign(cloneDeep(day), { id: key });
      }
    } else {
      if (day.dateStart) {
        daysObject[day.id] = cloneDeep(day);

        let schoolCalendarItem = {
          ...defaultSchoolCalendarItem,
          eventType: day.eventType,
          dateStart: day.dateStart,
          dateEnd: day.dateEnd,
          multipleDays: !!(day.dateEnd - day.dateStart),
          totalDays: moment(day.dateEnd).diff(day.dateStart, "days") + 1
        };

        // here we update in schoolCalendar also with updated tasks
        schoolCalendarRef.child(day.id).set(schoolCalendarItem);
      } else {
        daysObject[day.id] = cloneDeep(day);
        delete daysObject[day.id].id;
        delete daysObject[day.id].academicYearId;

        // here we update in schoolCalendar also with updated tasks
        schoolCalendarRef.child(day.id).set(null);
      }
    }
  });

  return Object.keys(daysObject).length > 0 ? daysObject : null;
}

// REQUESTS

function* schoolUsersListenerOn({ payload }) {
  const { sid } = payload;
  try {
    const nodeSchoolUsersRef = firebase.database()
        .ref(`schoolUsers/${sid}`).orderByChild('userType').equalTo('guardian')

    const nodeRef = firebase.database()
        .ref(`schoolData/${sid}/users`).orderByChild('role').equalTo('guardian')

    const channel = eventChannel((emit) => {

     nodeSchoolUsersRef
          .once('value')
          .then(snapshot => {
            if (snapshot) {
              const schoolUsersArray = Object.values(snapshot.val());
              const transformedData = {};
              schoolUsersArray.forEach(item => {
                transformedData[item.id] = {
                  uid: item.id,
                  disabled: !item.active,
                  fid: item.hasOwnProperty('family') ? item.family : undefined,
                  name: {
                    en: item.hasOwnProperty('profile') ? item.profile.nameRomaji : '-',
                    ja: item.hasOwnProperty('profile') ? item.profile.nameKanji : '-',
                    ja_f: item.hasOwnProperty('profile') ? item.profile.nameFurigana : '-',
                  },
                  avatar: item.hasOwnProperty('profile') && item.profile.hasOwnProperty('avatar') ? item.profile.avatar : undefined,
                  type: item.hasOwnProperty('profile') && item.profile.hasOwnProperty('childRelationship') ? item.profile.childRelationship : undefined,
                  students: item.hasOwnProperty('profile') && item.hasOwnProperty('children') ? Object.keys(item.children) : [],
                  appVersion: '2.0.3',
                  lastLogin: undefined,
                  status: undefined,
                }
              })

              emit({
                eventType: 'init',
                key: snapshot.key,
                value: transformedData,
              });
            }
          })

      nodeRef.on('child_added', (snapshot) => {
        emit({
          eventType: 'child_added',
          key: snapshot.key,
          value: snapshot.val(),
        });
      });

      nodeRef.on('child_changed', (snapshot) => {
        if (snapshot.val()) {
          emit({
            eventType: 'child_added',
            key: snapshot.key,
            value: snapshot.val()
          })
        }
      });

      nodeRef.on('child_removed', (snapshot) => {
        emit({
          eventType: 'child_removed',
          key: snapshot.key,
          value: snapshot.val(),
        });
      });

      return () => {
        nodeRef.off();
      };
    });

    while (true) {
      const { socketAction, logoutAction, closeAction } = yield race({
        socketAction: take(channel),
        logoutAction: take(logout),
        closeAction: take(schoolListenerOffSchoolUsers),
      });

      if (closeAction) {
        channel.close();
        yield put(
            schoolListenerEventSchoolUsers({ eventType: 'channel_closed' })
        );
      } else if (logoutAction) {
        channel.close();
        yield put(schoolListenerEventSchoolUsers(logoutAction));
      } else {
        yield put(
            schoolListenerEventSchoolUsers({
              eventType: socketAction.eventType,
              key: socketAction.key,
              value: socketAction.value,
            })
        );
      }
    }
  } catch (error) {
  }
}


// FUNCTIONS
function* fetchSchoolProfile({ payload }) {
  try {
    const schoolProfile = yield call(
      rsf.database.read,
      "schoolProfile/" + payload.school
    );

    if (schoolProfile){
      yield put(
          schoolFetchSchoolProfileSuccess({
            schoolProfile,
            school: payload.school
          })
      );
    } else {
      yield put(schoolFetchSchoolProfileFailure({ error:{
        message: 'No School Profile available.',
          code: 'noData'
        } }));
    }

  } catch (error) {
    yield put(schoolFetchSchoolProfileFailure({ error }));
  }
}

function* updateSchoolProfile({ payload }) {
  let { schoolId, schoolProfile, logo } = payload;
  const logoFile = logo;
  const prevLogoId = schoolProfile.logoId && schoolProfile.logoId === '' ? schoolProfile.logoId : null;

  try {
    const tokenId = yield call(getUserIdToken);
    if (logoFile) {
      const formData = new FormData();
      formData.append('avatar', logoFile.file);
      const apiUrl = `/v1/file/${schoolId}/upload/avatar`;
      const fileData = yield call(apiCall, 'POST', apiUrl, tokenId, formData, true);
      if(fileData.files.length > 0){
        schoolProfile.logoId = fileData.files[0].id;
        schoolProfile.logoUrl = fileData.files[0].url.medium;
      }
    }
    // yield call(rsf.database.delete, "schoolProfile/" + schoolId);
    yield call(rsf.database.patch, "schoolProfile/" + schoolId, schoolProfile);

    yield put(
      schoolUpdateSchoolProfileSuccess({
        schoolProfile: schoolProfile
      })
    );

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.school_profile_update_success"
      })
    );
    if(logoFile && prevLogoId){
      yield call(apiCall, 'DELETE', `/v1/file/${schoolId}/delete/${prevLogoId}`, tokenId);
    }
  } catch (error) {
    console.log("ERROR:", error);
    yield put(schoolUpdateSchoolProfileFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: error.message
      })
    );
  }
}

function* fetchSchoolSettings({ payload }) {
  try {
    const schoolSettings = yield call(
      rsf.database.read,
      "schoolSettings/" + payload.school
    );

    yield put(
      schoolFetchSchoolSettingsSuccess({
        schoolSettings
      })
    );
  } catch (error) {
    yield put(schoolFetchSchoolSettingsFailure({ error }));
  }
}

function* addSchoolAcademicYear({ payload }) {
  try {
    const {
      schoolId,
      dateStart,
      dateEnd,
      title,
      notes,
      closedDaysList,
      userId
    } = payload;

    const timestamp = moment()
      .utc()
      .valueOf();

    // schoolAcademicYear add Item
    let schoolAcademicYear = {
      dateStart: dateStart,
      dateEnd: dateEnd,
      title: title,
      notes: notes,
      createdBy: userId,
      createdAt: timestamp,
      updatedBy: userId,
      updatedAt: timestamp,
      closedDays: null,
      nationalHolidayDays: null,
      regularHolidayDays: null
    };

    // take empty closedDays object for schoolAcademiYear
    let closedDays = {};

    /* schoolCalendar start */

    // create reference for the school in firebase
    //const academicYearId = yield call(rsf.database.create, `/schoolCalendar/${schoolId}/`, {});
    const academicYearId = yield call(
      rsf.database.create,
      `/schoolCalendar/${schoolId}/all/`,
      {}
    );

    // take referance for schoolCalendar
    //let schoolCalendarRef = firebase.database().ref(`/schoolCalendar/${schoolId}/`);
    let schoolCalendarRef = firebase
      .database()
      .ref(`/schoolCalendar/${schoolId}/all/`);

    // schoolCalendarItem add Item
    closedDaysList.forEach(async closedDay => {
      let schoolCalendarItem = {
        eventType: closedDay.eventType,
        dateStart: closedDay.dateStart,
        dateEnd: closedDay.dateEnd,
        title: title,
        notes: notes,
        createdBy: userId,
        createdAt: timestamp,
        updatedBy: userId,
        updatedAt: timestamp,
        multipleDays: false,
        totalDays: 1,
        showInCalendar: true,
        academicYearId
      };

      // push data in schoolCalendar and get key from there
      const key = schoolCalendarRef.push(schoolCalendarItem).key;

      // add closed
      closedDay["academicYearId"] = academicYearId;
      closedDays[key] = closedDay;
    });

    /* schoolCalendar end */

    // overwrite closedDays in schoolAcademic year
    Object.assign(schoolAcademicYear, { closedDays });

    // add school academic year
    yield call(
      rsf.database.update,
      `/schoolAcademicYear/${schoolId}/${academicYearId}`,
      schoolAcademicYear
    );

    yield put(
      schoolAcademicYearAddSchoolAcademicYearSuccess({
        schoolAcademicYear,
        schoolAcademicYearId: academicYearId
      })
    );

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.school_academic_year_create_success"
      })
    );
  } catch (error) {
    yield put(schoolAcademicYearAddSchoolAcademicYearFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* updateSchoolAcademicYear({ payload }) {
  try {
    const {
      academicYearId,
      schoolId,
      dateStart,
      dateEnd,
      title,
      notes,
      closedDaysList,
      nationalHolidayDaysList,
      regularHolidayDaysList,
      userId
    } = payload;

    const timestamp = moment()
      .utc()
      .valueOf();

    // schoolAcademicYear add Item
    let schoolAcademicYear = {
      dateStart: dateStart,
      dateEnd: dateEnd,
      title: title,
      notes: notes,
      updatedBy: userId,
      updatedAt: timestamp,
      closedDays: null,
      nationalHolidayDays: null,
      regularHolidayDays: null
    };

    /* schoolCalendar start */

    // take reference for schoolCalendar
    //let schoolCalendarRef = firebase.database().ref(`/schoolCalendar/${schoolId}/`);
    let schoolCalendarRef = firebase
      .database()
      .ref(`/schoolCalendar/${schoolId}/all/`);

    //here we take common fields as we don't need to pass it to helpers
    let schoolCalendarItem = {
      title: title,
      notes: notes,
      createdBy: userId,
      createdAt: timestamp,
      updatedBy: userId,
      updatedAt: timestamp,
      showInCalendar: true,
      academicYearId
    };

    let closedDays = getDaysFireBaseObjectFromDaysListAndAddEventToSchoolCalendar(
      closedDaysList,
      schoolCalendarRef,
      schoolCalendarItem
    );
    let nationalHolidayDays = getDaysFireBaseObjectFromDaysListAndAddEventToSchoolCalendar(
      nationalHolidayDaysList,
      schoolCalendarRef,
      schoolCalendarItem
    );
    let regularHolidayDays = getDaysFireBaseObjectFromDaysListAndAddEventToSchoolCalendar(
      regularHolidayDaysList,
      schoolCalendarRef,
      schoolCalendarItem
    );

    /* schoolCalendar end */

    // overwrite closedDays, nationalHolidayDays, regularHolidayDays in schoolAcademic year
    Object.assign(schoolAcademicYear, {
      closedDays,
      nationalHolidayDays,
      regularHolidayDays
    });

    // update school academic year
    yield call(
      rsf.database.patch,
      `/schoolAcademicYear/${schoolId}/${academicYearId}`,
      schoolAcademicYear
    );

    yield put(schoolAcademicYearUpdateSchoolAcademicYearSuccess({}));

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.school_academic_year_update_success"
      })
    );
  } catch (error) {
    yield put(schoolAcademicYearUpdateSchoolAcademicYearFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* deleteSchoolAcademicYear({ payload }) {
  const { schoolId, academicYearList } = payload;

  try {
    let academicYearListObject = {};

    academicYearList.forEach(async academicYearObj => {
      // add academicYearList in academicYearListObject
      Object.assign(academicYearListObject, {
        [academicYearObj.academicYearId]: null
      });

      // Delete/remove events from schoolCalendar for academicYear
      //const academicYearRef = firebase.database().ref(`/schoolCalendar/${schoolId}`);
      const academicYearRef = firebase
        .database()
        .ref(`/schoolCalendar/${schoolId}/all`);
      await academicYearRef
        .orderByChild("academicYearId")
        .equalTo(academicYearObj.academicYearId)
        .once("value", snapshot => {
          const schoolCalendarEventsListObject = {};
          snapshot.forEach(
            child => (schoolCalendarEventsListObject[child.key] = null)
          );
          academicYearRef.update(schoolCalendarEventsListObject);
        });
    });

    // Delete academicYear
    yield call(
      rsf.database.patch,
      `/schoolAcademicYear/${schoolId}/`,
      academicYearListObject
    );

    yield put(schoolAcademicYearDeleteSchoolAcademicYearSuccess({}));

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.school_academic_year_delete_success"
      })
    );
  } catch (error) {
    console.log(error, "error");
    yield put(schoolAcademicYearDeleteSchoolAcademicYearFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* fetchSchoolAcademicYearList({ payload }) {
  const { schoolId } = payload;

  try {
    const schoolAcademicYearList = yield call(
      rsf.database.read,
      `schoolAcademicYear/${schoolId}/`
    );
    yield put(
      schoolAcademicYearFetchSchoolAcademicYearListSuccess(
        schoolAcademicYearList
      )
    );
  } catch (error) {
    yield put(schoolAcademicYearFetchSchoolAcademicYearListFailure());
  }
}

function* fetchSchoolRoomList({ payload }) {
  const { schoolId } = payload;

  try {
    const schoolRoomsList = yield call(
      rsf.database.read,
      `schoolRooms/${schoolId}`
    );

    yield put(schoolRoomFetchRoomListSuccess(schoolRoomsList));
  } catch (error) {
    yield put(schoolRoomFetchRoomListFailure({ error }));
  }
}

function* addSchoolRoom({ payload }) {
  try {
    const { schoolId, room } = payload;

    // add school room
    const key = yield call(rsf.database.create, `schoolRooms/${schoolId}`, {});

    // firebase.database.ref(`schoolRooms/${schoolId}`).push().key;
    // const updatedRoom = Object.assign(room, { id: key });

    yield call(
      rsf.database.patch,
      `schoolRooms/${schoolId}/${key}`,
      Object.assign(room, { id: key })
    );

    yield put(schoolRoomAddRoomSuccess());

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.school_room_create_success"
      })
    );
  } catch (error) {
    yield put(schoolRoomAddRoomFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* updateSchoolRoom({ payload }) {
  try {
    const { schoolId, room } = payload;

    // update school room

    yield call(rsf.database.patch, `schoolRooms/${schoolId}/${room.id}`, room);

    yield put(schoolRoomUpdateRoomSuccess());

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.school_room_update_success"
      })
    );
  } catch (error) {
    yield put(schoolRoomUpdateRoomFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* deleteSchoolRoomList({ payload }) {
  try {
    const { schoolId, roomIdList } = payload;

    const roomIdListObj = roomIdList.reduce((arr, roomId) => {
      return Object.assign(arr, { [roomId]: null });
    }, {});

    // Update school room
    yield call(rsf.database.patch, `schoolRooms/${schoolId}`, roomIdListObj);

    yield put(schoolRoomUpdateRoomSuccess());

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.school_room_delete_success"
      })
    );
  } catch (error) {
    yield put(schoolRoomUpdateRoomFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

// EXPORTS

// SYNCS

function* syncSchoolMetadata({ payload }) {
  const { school, role } = payload;

  if (role === "admin" || role === "manager") {
    const sync = yield fork(rsf.database.sync, `schoolMetadata/${school}`, {
      successActionCreator: schoolSyncSchoolMetadataSuccess,
      failureActionCreator: schoolSyncSchoolMetadataFailure
    });

    // On unsync request, cancel sync.
    yield take(AUTH_LOGOUT_SUCCESS);
    yield cancel(sync);
  }
}

function* syncSchoolAcademicYearList({ payload }) {
  const { schoolId } = payload;
  const nodeRef = firebase.database().ref(`schoolAcademicYear/${schoolId}/`);
  try {
    let sync = yield fork(rsf.database.sync, nodeRef, {
      successActionCreator: schoolAcademicYearSyncSchoolAcademicYearListSuccess,
      failureActionCreator: schoolAcademicYearSyncSchoolAcademicYearListFailure
    });

    // Wait for the pause action, then stop sync
    yield take(SCHOOL_ACADEMICYEAR_UNSYNC_SCHOOL_ACADEMICYEAR_LIST);
    yield cancel(sync);
  } catch (e) {
    console.log(e);
  }
}

function* syncSchoolRoomList({ payload }) {
  const { schoolId } = payload;
  const nodeRef = firebase.database().ref(`schoolRooms/${schoolId}/`);

  try {
    let sync = yield fork(rsf.database.sync, nodeRef, {
      successActionCreator: schoolRoomSyncRoomListSuccess,
      failureActionCreator: schoolRoomSyncRoomListFailure
    });

    // Wait for the pause action, then stop sync
    yield take(SCHOOL_ROOM_UNSYNC_ROOM_LIST);
    yield cancel(sync);
  } catch (e) {
    console.log(e);
  }
}

// WATCHERS

export default function* rootSaga() {
  yield all([
    takeEvery(AUTH_LOGIN_SUCCESS, fetchSchoolProfile),
    takeEvery(AUTH_LOGIN_SUCCESS, fetchSchoolSettings),
    takeEvery(AUTH_LOGIN_SUCCESS, syncSchoolMetadata),
    takeEvery(SCHOOL_FETCH_SCHOOL_PROFILE, fetchSchoolProfile),
    takeEvery(SCHOOL_UPDATE_SCHOOL_PROFILE, updateSchoolProfile),
    takeEvery(
      SCHOOL_ACADEMICYEAR_ADD_SCHOOL_ACADEMICYEAR,
      addSchoolAcademicYear
    ),
    takeEvery(
      SCHOOL_ACADEMICYEAR_UPDATE_SCHOOL_ACADEMICYEAR,
      updateSchoolAcademicYear
    ),
    takeEvery(
      SCHOOL_ACADEMICYEAR_DELETE_SCHOOL_ACADEMICYEAR,
      deleteSchoolAcademicYear
    ),
    takeLatest(
      SCHOOL_ACADEMICYEAR_FETCH_SCHOOL_ACADEMICYEAR_LIST,
      fetchSchoolAcademicYearList
    ),
    takeLatest(
      SCHOOL_ACADEMICYEAR_SYNC_SCHOOL_ACADEMICYEAR_LIST,
      syncSchoolAcademicYearList
    ),
    takeEvery(SCHOOL_ROOM_FETCH_ROOM_LIST, fetchSchoolRoomList),
    takeEvery(SCHOOL_ROOM_ADD_ROOM, addSchoolRoom),
    takeEvery(SCHOOL_ROOM_UPDATE_ROOM, updateSchoolRoom),
    takeLatest(SCHOOL_ROOM_SYNC_ROOM_LIST, syncSchoolRoomList),
    takeEvery(SCHOOL_ROOM_DELETE_ROOM_LIST, deleteSchoolRoomList),
    takeEvery(SCHOOL_LISTENER_ON_SCHOOL_USERS, schoolUsersListenerOn)


  ]);
}
